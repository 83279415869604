import React from 'react';
import { Info } from 'lucide-react';
import { Note } from '../types/note';

interface ImportedNotesBannerProps {
  note: Note;
}

export function ImportedNotesBanner({ note }: ImportedNotesBannerProps) {
  if (!note.importMetadata) return null;

  return (
    <div className="bg-indigo-50 dark:bg-indigo-900/20 border-b border-indigo-100 dark:border-indigo-800 px-4 py-2">
      <div className="flex items-center gap-2 text-sm text-indigo-600 dark:text-indigo-400">
        <Info size={16} />
        <span>
          Imported from {note.importMetadata.source} on{' '}
          {new Date(note.importMetadata.importedAt).toLocaleDateString()}
        </span>
      </div>
    </div>
  );
}