import { create } from 'zustand';
import { Folder } from '../types/folder';

interface FoldersStore {
  folders: Folder[];
  activeFolderId: string | null;
  importedFolderId: string | null;
  setActiveFolderId: (id: string | null) => void;
  addFolder: (name: string, parentId: string | null) => string;
  updateFolder: (id: string, name: string) => void;
  deleteFolder: (id: string) => void;
  setFolders: (folders: Folder[]) => void;
  ensureImportedFolder: () => string;
}

const IMPORTED_FOLDER_NAME = 'Imported';

const loadFolders = (): Folder[] => {
  try {
    const folders = localStorage.getItem('folders');
    return folders ? JSON.parse(folders) : [];
  } catch (error) {
    console.error('Failed to load folders:', error);
    return [];
  }
};

export const useFoldersStore = create<FoldersStore>((set, get) => ({
  folders: loadFolders(),
  activeFolderId: null,
  importedFolderId: null,

  setActiveFolderId: (id) => set({ activeFolderId: id }),

  addFolder: (name, parentId) => {
    const newFolder: Folder = {
      id: crypto.randomUUID(),
      name,
      parentId,
      createdAt: Date.now(),
    };

    set((state) => {
      const folders = [...state.folders, newFolder];
      localStorage.setItem('folders', JSON.stringify(folders));
      return { folders };
    });

    return newFolder.id;
  },

  updateFolder: (id, name) => {
    set((state) => {
      const folders = state.folders.map((folder) =>
        folder.id === id ? { ...folder, name } : folder
      );
      localStorage.setItem('folders', JSON.stringify(folders));
      return { folders };
    });
  },

  deleteFolder: (id) => {
    set((state) => {
      // Don't allow deleting the imported folder
      if (id === state.importedFolderId) {
        return state;
      }

      const folders = state.folders.filter((folder) => folder.id !== id);
      localStorage.setItem('folders', JSON.stringify(folders));
      return {
        folders,
        activeFolderId: state.activeFolderId === id ? null : state.activeFolderId,
      };
    });
  },

  setFolders: (folders) => {
    localStorage.setItem('folders', JSON.stringify(folders));
    set({ folders, activeFolderId: null });
  },

  ensureImportedFolder: () => {
    const state = get();
    const existingFolder = state.folders.find(f => f.name === IMPORTED_FOLDER_NAME);
    
    if (existingFolder) {
      set({ importedFolderId: existingFolder.id });
      return existingFolder.id;
    }

    const newFolderId = state.addFolder(IMPORTED_FOLDER_NAME, null);
    set({ importedFolderId: newFolderId });
    return newFolderId;
  },
}));