import { StorageOptions } from './types';

export function validateContent(content: string, options?: StorageOptions): boolean {
  if (!content) return false;

  const size = new Blob([content]).size;
  if (options?.validationRules?.maxSize && size > options.validationRules.maxSize) {
    throw new Error(`Content exceeds maximum size of ${options.validationRules.maxSize} bytes`);
  }

  if (options?.validationRules?.allowedTypes) {
    const contentType = content.startsWith('<') ? 'text/html' : 'text/plain';
    if (!options.validationRules.allowedTypes.includes(contentType)) {
      throw new Error(`Content type ${contentType} is not allowed`);
    }
  }

  return true;
}

export function sanitizeContent(content: string): string {
  return content
    .replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '')
    .replace(/javascript:/gi, '')
    .replace(/on\w+=/gi, '');
}