import { useEffect } from 'react';
import { useNotesStore } from '../store/useNotesStore';
import { useFoldersStore } from '../store/useFoldersStore';

export function useBackButton() {
  const { activeNoteId, setActiveNote } = useNotesStore();
  const { activeFolderId, setActiveFolderId } = useFoldersStore();

  useEffect(() => {
    const handleBackButton = (event: PopStateEvent) => {
      event.preventDefault();

      // If we're in a note, go back to the folder view
      if (activeNoteId) {
        setActiveNote(null);
        return;
      }

      // If we're in a folder, go back to root
      if (activeFolderId) {
        setActiveFolderId(null);
        return;
      }

      // If we're at root level, let the browser handle it
      // This will typically show the PWA installation prompt or close the app
      if (!activeNoteId && !activeFolderId) {
        if (window.matchMedia('(display-mode: standalone)').matches) {
          // Only show confirmation in standalone mode (PWA)
          if (window.confirm('Do you want to close the app?')) {
            window.close();
          }
        }
      }
    };

    // Push initial state
    window.history.pushState({ page: 'root' }, '');

    // Handle back button
    window.addEventListener('popstate', handleBackButton);

    // Push state when note or folder changes
    if (activeNoteId) {
      window.history.pushState({ page: 'note', id: activeNoteId }, '');
    } else if (activeFolderId) {
      window.history.pushState({ page: 'folder', id: activeFolderId }, '');
    }

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [activeNoteId, activeFolderId, setActiveNote, setActiveFolderId]);
}