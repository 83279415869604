interface EditorPropsConfig {
  isEmpty: boolean;
  isFocused: boolean;
  placeholder: string;
}

export const createEditorProps = ({ isEmpty, isFocused, placeholder }: EditorPropsConfig) => ({
  attributes: {
    class: [
      'prose',
      'prose-sm',
      'sm:prose',
      'lg:prose-lg',
      'dark:prose-invert',
      'focus:outline-none',
      'max-w-none',
      'p-4',
      'min-h-[calc(100vh-12rem)]',
      'md:min-h-[calc(100vh-14rem)]',
      'overflow-x-hidden',
      'break-words',
      isEmpty && !isFocused ? 'editor-empty' : ''
    ].join(' ')
  },
  handleDOMEvents: {
    focus: (view, event) => {
      if (isEmpty && !isFocused) {
        view.dispatch(view.state.tr.insertText(''));
      }
      return false;
    }
  },
});