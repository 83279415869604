import React, { useState } from 'react';
import { Plus, Wand2, X } from 'lucide-react';
import { useNotesStore } from '../store/useNotesStore';
import { useFoldersStore } from '../store/useFoldersStore';
import { ImportExport } from './ImportExport';
import { FolderTree } from './FolderTree';
import { ThemeToggle } from './ThemeToggle';
import { AINoteDialog } from './AINoteDialog';
import { SettingsDialog } from './SettingsDialog';
import { Logo } from './Logo';
import { KnowMore } from './san-juniper/KnowMore';

interface SidebarProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export function Sidebar({ isOpen, onClose }: SidebarProps) {
  const { addNote } = useNotesStore();
  const { activeFolderId } = useFoldersStore();
  const [isAIDialogOpen, setIsAIDialogOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  return (
    <div className={`
      fixed inset-y-0 left-0 z-40 w-64 bg-white dark:bg-black border-r border-gray-200 dark:border-gray-800 
      flex flex-col h-screen transform transition-transform duration-300 ease-in-out
      md:relative md:transform-none
      ${isOpen ? 'translate-x-0' : '-translate-x-full md:translate-x-0'}
    `}>
      {onClose && (
        <button
          onClick={onClose}
          className="absolute right-2 top-2 p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-800 md:hidden"
          aria-label="Close sidebar"
        >
          <X size={20} className="text-gray-500 dark:text-gray-400" />
        </button>
      )}

      <Logo />

      <div className="p-4 border-b border-gray-200 dark:border-gray-800 space-y-2">
        <button
          onClick={() => addNote(activeFolderId)}
          className="w-full flex items-center justify-center gap-2 bg-black dark:bg-white text-white dark:text-black px-4 py-2 rounded-lg hover:bg-gray-800 dark:hover:bg-gray-200 transition-colors"
        >
          <Plus size={20} />
          New Note
        </button>
        <button
          onClick={() => setIsAIDialogOpen(true)}
          className="w-full flex items-center justify-center gap-2 bg-indigo-600 text-white px-4 py-2 rounded-lg hover:bg-indigo-700 transition-colors"
        >
          <Wand2 size={20} />
          AI Generate
        </button>
      </div>

      <div className="flex-1 overflow-y-auto">
        <FolderTree />
      </div>

      <KnowMore />

      <div className="border-t border-gray-200 dark:border-gray-800">
        <div className="p-4 flex items-center justify-between">
          <ImportExport />
          <ThemeToggle />
        </div>
      </div>

      <AINoteDialog 
        isOpen={isAIDialogOpen} 
        onClose={() => setIsAIDialogOpen(false)}
        onOpenSettings={() => setIsSettingsOpen(true)}
      />
      <SettingsDialog 
        isOpen={isSettingsOpen} 
        onClose={() => setIsSettingsOpen(false)} 
      />
    </div>
  );
}