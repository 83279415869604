import { StorageUpdate, StorageOptions } from './types';

export class UpdateQueue {
  private queue: Map<string, StorageUpdate>;
  private processing: boolean;
  private options: StorageOptions;

  constructor(options: StorageOptions = {}) {
    this.queue = new Map();
    this.processing = false;
    this.options = options;
  }

  public add(update: StorageUpdate): void {
    this.queue.set(update.id, {
      ...update,
      retryCount: 0,
      status: 'pending'
    });
  }

  public get(id: string): StorageUpdate | undefined {
    return this.queue.get(id);
  }

  public remove(id: string): void {
    this.queue.delete(id);
  }

  public clear(): void {
    this.queue.clear();
    this.processing = false;
  }

  public markAsProcessing(id: string): void {
    const update = this.queue.get(id);
    if (update) {
      this.queue.set(id, { ...update, status: 'processing' });
      this.processing = true;
    }
  }

  public markAsComplete(id: string): void {
    this.remove(id);
    this.processing = false;
  }

  public markAsError(id: string): void {
    const update = this.queue.get(id);
    if (update) {
      const retryCount = (update.retryCount || 0) + 1;
      if (retryCount <= (this.options.maxRetries || 3)) {
        this.queue.set(id, {
          ...update,
          retryCount,
          status: 'pending'
        });
      } else {
        this.queue.set(id, {
          ...update,
          status: 'error'
        });
      }
    }
    this.processing = false;
  }

  public isProcessing(): boolean {
    return this.processing;
  }
}