import React from 'react';

interface SaveStatusProps {
  status: string;
}

export function SaveStatus({ status }: SaveStatusProps) {
  if (status !== 'saving') return null;

  return (
    <div className="fixed bottom-4 right-4 bg-gray-800 text-white px-3 py-1 rounded-lg text-sm shadow-lg z-50">
      Saving...
    </div>
  );
}