import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { registerSW } from 'virtual:pwa-register';
import App from './App.tsx';
import { ErrorBoundary } from './components/ErrorBoundary';
import './index.css';

// Register service worker with less aggressive update checking
const updateSW = registerSW({
  onNeedRefresh() {
    if (confirm('New content available. Reload?')) {
      updateSW(true); // Force update when user confirms
    }
  },
  onOfflineReady() {
    console.log('App ready to work offline');
  },
  immediate: false,
  periodicIntervalForUpdates: 60 * 60 * 1000, // Check for updates every hour instead of continuously
});

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </StrictMode>
);