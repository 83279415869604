import React, { useState } from 'react';
import { ChevronRight, ChevronDown, Folder, FolderPlus, Edit2, Trash2, Plus, Archive, Inbox } from 'lucide-react';
import { useFoldersStore } from '../store/useFoldersStore';
import { useNotesStore } from '../store/useNotesStore';
import { useNavigate } from '../hooks/useNavigate';

interface SystemFolder {
  id: string;
  name: string;
  icon: React.ReactNode;
}

interface FolderItemProps {
  id: string;
  name: string;
  level: number;
  hasChildren: boolean;
  isExpanded: boolean;
  noteCount: number;
  isSystem?: boolean;
  icon?: React.ReactNode;
  onToggle: (id: string) => void;
  onAddSubfolder?: (parentId: string) => void;
}

function FolderItem({ 
  id, 
  name, 
  level, 
  hasChildren, 
  isExpanded, 
  noteCount,
  isSystem,
  icon,
  onToggle,
  onAddSubfolder,
}: FolderItemProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [editName, setEditName] = useState(name);
  const [showActions, setShowActions] = useState(false);
  const { activeFolderId, updateFolder, deleteFolder, setActiveFolderId } = useFoldersStore();
  const { moveNotesToFolder } = useNotesStore();
  const { navigateToFolder } = useNavigate();

  const handleUpdate = () => {
    if (editName.trim() && editName !== name) {
      updateFolder(id, editName.trim());
    }
    setIsEditing(false);
  };

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this folder and move all its notes to root?')) {
      moveNotesToFolder(id, null);
      deleteFolder(id);
    }
  };

  const handleFolderClick = () => {
    setActiveFolderId(id);
    navigateToFolder(id);
  };

  return (
    <div 
      className={`group relative flex flex-col ${
        activeFolderId === id ? 'bg-indigo-50 dark:bg-gray-800' : ''
      }`}
    >
      <div
        className="flex items-center px-2 py-2 hover:bg-gray-100 dark:hover:bg-gray-800 cursor-pointer"
        style={{ paddingLeft: `${level * 1.5}rem` }}
        onClick={handleFolderClick}
        onMouseEnter={() => setShowActions(true)}
        onMouseLeave={() => setShowActions(false)}
      >
        {!isSystem && hasChildren && (
          <button
            className="p-1 hover:bg-gray-200 dark:hover:bg-gray-700 rounded text-gray-600 dark:text-gray-300"
            onClick={(e) => {
              e.stopPropagation();
              onToggle(id);
            }}
          >
            {isExpanded ? (
              <ChevronDown size={16} />
            ) : (
              <ChevronRight size={16} />
            )}
          </button>
        )}

        {icon || <Folder size={16} className="mr-2 text-gray-400 dark:text-gray-500" />}

        {isEditing && !isSystem ? (
          <input
            type="text"
            value={editName}
            onChange={(e) => setEditName(e.target.value)}
            onBlur={handleUpdate}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleUpdate();
              if (e.key === 'Escape') setIsEditing(false);
            }}
            className="flex-1 bg-white dark:bg-black border dark:border-gray-700 rounded px-2 py-1 text-sm mr-2 text-gray-900 dark:text-white"
            autoFocus
            onClick={(e) => e.stopPropagation()}
          />
        ) : (
          <>
            <span className="flex-1 text-sm font-medium truncate">{name}</span>
            <span className="text-xs text-gray-500 dark:text-gray-400 mr-2">{noteCount}</span>
          </>
        )}

        {!isSystem && showActions && (
          <div className="flex gap-1">
            {onAddSubfolder && (
              <button
                className="p-1 hover:bg-gray-200 dark:hover:bg-gray-700 rounded text-gray-600 dark:text-gray-300"
                onClick={(e) => {
                  e.stopPropagation();
                  onAddSubfolder(id);
                }}
                title="Add subfolder"
              >
                <Plus size={14} />
              </button>
            )}
            <button
              className="p-1 hover:bg-gray-200 dark:hover:bg-gray-700 rounded text-gray-600 dark:text-gray-300"
              onClick={(e) => {
                e.stopPropagation();
                setIsEditing(true);
              }}
              title="Rename folder"
            >
              <Edit2 size={14} />
            </button>
            <button
              className="p-1 hover:bg-gray-200 dark:hover:bg-gray-700 rounded text-gray-600 dark:text-gray-300"
              onClick={(e) => {
                e.stopPropagation();
                handleDelete();
              }}
              title="Delete folder"
            >
              <Trash2 size={14} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export function FolderTree() {
  const [expandedFolders, setExpandedFolders] = useState<Set<string>>(new Set());
  const { folders, addFolder, importedFolderId } = useFoldersStore();
  const { notes } = useNotesStore();
  const { navigateToFolder } = useNavigate();

  const toggleFolder = (id: string) => {
    setExpandedFolders((prev) => {
      const next = new Set(prev);
      if (next.has(id)) {
        next.delete(id);
      } else {
        next.add(id);
      }
      return next;
    });
  };

  const handleAddFolder = (parentId: string | null) => {
    const name = window.prompt('Enter folder name:');
    if (name?.trim()) {
      addFolder(name.trim(), parentId);
      if (parentId) {
        setExpandedFolders((prev) => new Set([...prev, parentId]));
      }
    }
  };

  const getFolderNoteCount = (folderId: string | null): number => {
    return notes.filter(note => note.folderId === folderId).length;
  };

  const systemFolders: SystemFolder[] = [
    { id: 'all', name: 'All Notes', icon: <Inbox size={16} className="mr-2 text-gray-400 dark:text-gray-500" /> },
    { id: 'uncategorized', name: 'Uncategorized', icon: <Archive size={16} className="mr-2 text-gray-400 dark:text-gray-500" /> },
  ];

  if (importedFolderId) {
    systemFolders.push({
      id: importedFolderId,
      name: 'Imported',
      icon: <Folder size={16} className="mr-2 text-indigo-400" />
    });
  }

  const userFolders = folders.filter(f => f.id !== importedFolderId);

  const renderFolderTree = (parentId: string | null, level: number = 0) => {
    const children = userFolders.filter((f) => f.parentId === parentId);
    
    return children.map((folder) => {
      const hasChildren = userFolders.some((f) => f.parentId === folder.id);
      const isExpanded = expandedFolders.has(folder.id);
      const noteCount = getFolderNoteCount(folder.id);
      
      return (
        <React.Fragment key={folder.id}>
          <FolderItem
            id={folder.id}
            name={folder.name}
            level={level}
            hasChildren={hasChildren}
            isExpanded={isExpanded}
            noteCount={noteCount}
            onToggle={toggleFolder}
            onAddSubfolder={handleAddFolder}
          />
          {isExpanded && renderFolderTree(folder.id, level + 1)}
        </React.Fragment>
      );
    });
  };

  return (
    <div className="mb-4">
      <div className="px-3 py-2">
        <h2 className="text-xs font-semibold text-gray-500 dark:text-gray-400 uppercase tracking-wider mb-2">
          System
        </h2>
        {systemFolders.map((folder) => (
          <FolderItem
            key={folder.id}
            id={folder.id}
            name={folder.name}
            level={0}
            hasChildren={false}
            isExpanded={false}
            noteCount={folder.id === 'all' ? notes.length : getFolderNoteCount(folder.id === 'uncategorized' ? null : folder.id)}
            isSystem={true}
            icon={folder.icon}
            onToggle={() => {}}
          />
        ))}
      </div>

      <div className="mt-4 px-3 py-2">
        <div className="flex items-center justify-between mb-2">
          <h2 className="text-xs font-semibold text-gray-500 dark:text-gray-400 uppercase tracking-wider">
            Folders
          </h2>
          <button
            onClick={() => handleAddFolder(null)}
            className="p-1.5 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg text-gray-600 dark:text-gray-300 transition-colors"
            title="Add folder"
          >
            <FolderPlus size={16} />
          </button>
        </div>
        {renderFolderTree(null)}
      </div>
    </div>
  );
}