import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Note } from '../types/note';
import { storage } from '../utils/storage/database';

interface NotesStore {
  notes: Note[];
  activeNoteId: string | null;
  searchQuery: string;
  lastViewedNote: Note | null;
  isInitialized: boolean;
  isLoading: boolean;
  error: string | null;
  setSearchQuery: (query: string) => void;
  addNote: (folderId: string | null, initialData?: Partial<Note>) => Promise<void>;
  updateNote: (id: string, updates: Partial<Note>) => Promise<void>;
  deleteNote: (id: string) => Promise<void>;
  setActiveNote: (id: string | null) => void;
  setNotes: (notes: Note[]) => Promise<void>;
  moveNotesToFolder: (fromFolderId: string, toFolderId: string | null) => Promise<void>;
  initializeNotes: () => Promise<void>;
  loadNotes: () => Promise<void>;
  setError: (error: string | null) => void;
}

export const useNotesStore = create<NotesStore>((set, get) => ({
  notes: [],
  activeNoteId: null,
  lastViewedNote: null,
  searchQuery: '',
  isInitialized: false,
  isLoading: true,
  error: null,

  setSearchQuery: (query) => set({ searchQuery: query }),
  setError: (error) => set({ error }),

  loadNotes: async () => {
    try {
      set({ isLoading: true, error: null });
      const notes = await storage.getAllNotes();
      set({ 
        notes: notes.filter(note => !note.deleted),
        isLoading: false
      });
    } catch (error) {
      console.error('Failed to load notes:', error);
      set({ 
        notes: [], 
        isLoading: false,
        error: 'Failed to load notes. Please check your browser settings.'
      });
    }
  },

  addNote: async (folderId, initialData = {}) => {
    try {
      set({ error: null });
      const newNote: Note = {
        id: crypto.randomUUID(),
        title: initialData.title || 'Untitled Note',
        content: initialData.content || '<p>Start writing your note here...</p>',
        lastModified: Date.now(),
        folderId,
        compressed: false,
        deleted: false,
      };

      await storage.saveNote(newNote);
      set((state) => ({
        notes: [...state.notes, newNote],
        activeNoteId: newNote.id,
      }));
    } catch (error) {
      console.error('Failed to add note:', error);
      set({ error: 'Failed to create note. Please try again.' });
    }
  },

  updateNote: async (id, updates) => {
    try {
      set({ error: null });
      const { notes } = get();
      const noteToUpdate = notes.find(n => n.id === id);
      
      if (!noteToUpdate) {
        throw new Error('Note not found');
      }

      const updatedNote = {
        ...noteToUpdate,
        ...updates,
        lastModified: Date.now(),
      };

      await storage.saveNote(updatedNote);
      set(state => ({
        notes: state.notes.map(note => 
          note.id === id ? updatedNote : note
        )
      }));
    } catch (error) {
      console.error('Failed to update note:', error);
      set({ error: 'Failed to save note changes. Please try again.' });
    }
  },

  deleteNote: async (id) => {
    try {
      set({ error: null });
      const { activeNoteId } = get();
      await storage.deleteNote(id);
      set(state => ({
        notes: state.notes.filter(note => note.id !== id),
        activeNoteId: activeNoteId === id ? null : activeNoteId,
      }));
    } catch (error) {
      console.error('Failed to delete note:', error);
      set({ error: 'Failed to delete note. Please try again.' });
    }
  },

  setActiveNote: (id) => set({ activeNoteId: id }),

  setNotes: async (notes) => {
    try {
      set({ error: null, isLoading: true });
      await Promise.all(notes.map(note => storage.saveNote(note)));
      set({ notes, activeNoteId: null, isLoading: false });
    } catch (error) {
      console.error('Failed to set notes:', error);
      set({ 
        error: 'Failed to import notes. Please try again.',
        isLoading: false 
      });
    }
  },

  moveNotesToFolder: async (fromFolderId, toFolderId) => {
    try {
      set({ error: null });
      const { notes } = get();
      const updatedNotes = notes.map(note => 
        note.folderId === fromFolderId
          ? { ...note, folderId: toFolderId, lastModified: Date.now() }
          : note
      );

      await Promise.all(
        updatedNotes
          .filter(note => note.folderId === toFolderId)
          .map(note => storage.saveNote(note))
      );

      set({ notes: updatedNotes });
    } catch (error) {
      console.error('Failed to move notes:', error);
      set({ error: 'Failed to move notes. Please try again.' });
    }
  },

  initializeNotes: async () => {
    const state = get();
    if (state.isInitialized) return;
    
    try {
      set({ isLoading: true, error: null });
      await storage.migrateFromLocalStorage();
      const notes = await storage.getAllNotes();
      set({ 
        notes: notes.filter(note => !note.deleted),
        isInitialized: true,
        isLoading: false
      });
    } catch (error) {
      console.error('Failed to initialize notes:', error);
      set({ 
        notes: [], 
        isInitialized: true,
        isLoading: false,
        error: 'Failed to initialize notes. Please check browser storage permissions.'
      });
    }
  },
}));