import { supabase } from './client';
import { Note } from '../../types/note';
import { ShareResult } from '../../types/share';
import { STORAGE_CONFIG, ERRORS } from './config';
import { transformShareUrl } from '../url';

export async function shareNote(note: Note): Promise<ShareResult> {
  try {
    const content = JSON.stringify({
      version: 1,
      type: 'note',
      data: note,
      metadata: {
        sharedAt: Date.now(),
        expiresAt: Date.now() + (STORAGE_CONFIG.EXPIRY_DAYS * 24 * 60 * 60 * 1000)
      }
    });

    const filename = `${crypto.randomUUID()}.meranotes`;
    const filePath = `shared/${filename}`;

    // Upload file to Supabase storage
    const { error: uploadError } = await supabase.storage
      .from(STORAGE_CONFIG.BUCKET_NAME)
      .upload(filePath, content, {
        contentType: 'application/json',
        cacheControl: '3600',
        upsert: false
      });

    if (uploadError) {
      console.error('Upload error:', uploadError);
      throw uploadError;
    }

    // Get public URL
    const { data: { publicUrl } } = supabase.storage
      .from(STORAGE_CONFIG.BUCKET_NAME)
      .getPublicUrl(filePath);

    const shareUrl = transformShareUrl(publicUrl);

    return {
      success: true,
      url: shareUrl,
      expiresAt: Date.now() + (STORAGE_CONFIG.EXPIRY_DAYS * 24 * 60 * 60 * 1000)
    };
  } catch (error) {
    console.error('Failed to share note:', error);
    return {
      success: false,
      error: error instanceof Error ? error.message : ERRORS.UPLOAD_FAILED
    };
  }
}

export async function getSharedNote(shareId: string): Promise<Note | null> {
  try {
    // Validate shareId format
    if (!shareId.match(/^[0-9a-f-]{36}$/)) {
      throw new Error(ERRORS.INVALID_SHARE_URL);
    }

    const { data, error } = await supabase.storage
      .from(STORAGE_CONFIG.BUCKET_NAME)
      .download(`shared/${shareId}.meranotes`);

    if (error) {
      if (error.message.includes('404')) {
        throw new Error(ERRORS.NOT_FOUND);
      }
      throw error;
    }

    if (!data) {
      throw new Error(ERRORS.NOT_FOUND);
    }

    const content = await data.text();
    const { data: note, metadata } = JSON.parse(content);

    // Check if file has expired
    if (metadata?.expiresAt && metadata.expiresAt < Date.now()) {
      throw new Error(ERRORS.EXPIRED);
    }

    return note;
  } catch (error) {
    console.error('Failed to get shared note:', error);
    return null;
  }
}

export async function checkFileExists(shareId: string): Promise<boolean> {
  try {
    const { data } = await supabase.storage
      .from(STORAGE_CONFIG.BUCKET_NAME)
      .list('shared', {
        search: `${shareId}.meranotes`
      });

    return data !== null && data.length > 0;
  } catch (error) {
    console.error('Failed to check file existence:', error);
    return false;
  }
}