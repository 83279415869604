import React, { useRef, useState } from 'react';
import { Download, Upload, Share2 } from 'lucide-react';
import { useNotesStore } from '../store/useNotesStore';
import { useFoldersStore } from '../store/useFoldersStore';
import { exportNotes, importNotes } from '../utils/storage';
import { ImportDialog } from './ImportDialog';
import { ShareDialog } from './ShareDialog';
import { ImportResult } from '../types/importResult';

export function ImportExport() {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { notes, setNotes } = useNotesStore();
  const { folders, setFolders } = useFoldersStore();
  const [importResult, setImportResult] = useState<ImportResult | null>(null);
  const [showShare, setShowShare] = useState(false);

  const handleExport = async () => {
    try {
      await exportNotes(notes, folders);
    } catch (error) {
      alert(error instanceof Error ? error.message : 'Failed to export notes');
    }
  };

  const handleImport = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      const { notes: importedNotes, folders: importedFolders } = await importNotes(file);
      setNotes(importedNotes);
      setFolders(importedFolders);
      setImportResult({
        success: true,
        summary: {
          successful: importedNotes.length,
          duplicates: 0,
          errors: 0,
          totalProcessed: importedNotes.length
        },
        errors: []
      });
    } catch (error) {
      setImportResult({
        success: false,
        summary: { successful: 0, duplicates: 0, errors: 1, totalProcessed: 0 },
        errors: [error instanceof Error ? error.message : 'Failed to import notes']
      });
    } finally {
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  return (
    <>
      <div className="flex gap-2">
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleImport}
          accept=".meranotes"
          className="hidden"
        />
        <button
          onClick={() => fileInputRef.current?.click()}
          className="p-2 text-gray-600 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg transition-colors"
          title="Import notes"
        >
          <Upload size={20} />
        </button>
        <button
          onClick={handleExport}
          className="p-2 text-gray-600 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg transition-colors"
          title="Export notes"
        >
          <Download size={20} />
        </button>
      </div>

      <ImportDialog
        isOpen={importResult !== null}
        onClose={() => setImportResult(null)}
        result={importResult!}
      />
    </>
  );
}