import React from 'react';
import { Menu } from 'lucide-react';

interface MobileNavProps {
  onOpenSidebar: () => void;
}

export function MobileNav({ onOpenSidebar }: MobileNavProps) {
  return (
    <button
      onClick={onOpenSidebar}
      className="fixed right-4 bottom-4 md:hidden z-50 bg-black dark:bg-white text-white dark:text-black p-4 rounded-full shadow-lg hover:scale-105 transition-transform"
      aria-label="Open menu"
    >
      <Menu size={24} />
    </button>
  );
}