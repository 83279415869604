import React, { useState } from 'react';
import { X, Share2, Copy, AlertTriangle } from 'lucide-react';
import { useShare } from '../../hooks/useShare';
import { STORAGE_CONFIG } from '../../utils/supabase/config';

interface ShareDialogProps {
  isOpen: boolean;
  onClose: () => void;
  note: Note;
}

export function ShareDialog({ isOpen, onClose, note }: ShareDialogProps) {
  const { isSharing, error, shareUrl, handleShare, clearError } = useShare();
  const [showConfirm, setShowConfirm] = useState(true);

  const handleShareClick = async () => {
    const result = await handleShare(note);
    if (result.success) {
      setShowConfirm(false);
    }
  };

  const handleCopyUrl = async () => {
    if (shareUrl) {
      try {
        await navigator.clipboard.writeText(shareUrl);
      } catch (error) {
        console.error('Failed to copy URL:', error);
      }
    }
  };

  const handleClose = () => {
    clearError();
    setShowConfirm(true);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-900 rounded-lg shadow-lg max-w-md w-full mx-4">
        <div className="flex items-center justify-between p-6 border-b border-gray-200 dark:border-gray-800">
          <div className="flex items-center gap-2">
            <Share2 className="w-5 h-5 text-indigo-500" />
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white">Share Note</h2>
          </div>
          <button
            onClick={handleClose}
            className="p-2 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-full transition-colors"
          >
            <X size={20} className="text-gray-500 dark:text-gray-400" />
          </button>
        </div>

        <div className="p-6">
          {error && (
            <div className="mb-4 p-4 bg-red-50 dark:bg-red-900/20 rounded-lg">
              <div className="flex items-center gap-2">
                <AlertTriangle className="w-5 h-5 text-red-500" />
                <p className="text-red-600 dark:text-red-400 text-sm">{error}</p>
              </div>
            </div>
          )}

          {showConfirm ? (
            <div className="space-y-4">
              <div className="space-y-2 text-sm text-gray-600 dark:text-gray-300">
                <p>• This file will be uploaded to MeraNotes cloud storage</p>
                <p>• You'll receive a unique sharing link valid for {STORAGE_CONFIG.EXPIRY_DAYS} days</p>
                <p>• The uploaded file will be automatically deleted after {STORAGE_CONFIG.EXPIRY_DAYS} days</p>
                <p>• Anyone with the link can view and import these notes</p>
              </div>
              <div className="flex justify-end gap-3 pt-2">
                <button
                  onClick={handleClose}
                  className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
                >
                  Cancel
                </button>
                <button
                  onClick={handleShareClick}
                  disabled={isSharing}
                  className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 rounded-lg transition-colors disabled:opacity-50"
                >
                  {isSharing ? 'Sharing...' : 'Share'}
                </button>
              </div>
            </div>
          ) : (
            <div className="space-y-4">
              <p className="text-sm text-gray-600 dark:text-gray-300">
                Your note has been shared! Copy the link below:
              </p>
              <div className="flex gap-2">
                <input
                  type="text"
                  value={shareUrl || ''}
                  readOnly
                  className="flex-1 px-3 py-2 bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg text-sm"
                />
                <button
                  onClick={handleCopyUrl}
                  className="px-3 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
                >
                  <Copy size={18} />
                </button>
              </div>
              <p className="text-xs text-gray-500 dark:text-gray-400">
                This link will expire in {STORAGE_CONFIG.EXPIRY_DAYS} days
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}