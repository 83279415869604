import React, { useMemo, useState } from 'react';
import { FileText, Search, SortAsc, SortDesc, Grid, List, Plus, Filter, Trash2, X, CheckSquare, Square } from 'lucide-react';
import { useNotesStore } from '../store/useNotesStore';
import { useFoldersStore } from '../store/useFoldersStore';
import { Note } from '../types/note';
import { ConfirmDialog } from './ConfirmDialog';

type ViewMode = 'grid' | 'list';
type SortField = 'title' | 'lastModified';
type SortOrder = 'asc' | 'desc';

interface SortConfig {
  field: SortField;
  order: SortOrder;
}

export function FolderPage() {
  const { notes, addNote, setActiveNote, deleteNote } = useNotesStore();
  const { folders, activeFolderId } = useFoldersStore();
  const [searchQuery, setSearchQuery] = useState('');
  const [viewMode, setViewMode] = useState<ViewMode>('list');
  const [showFilters, setShowFilters] = useState(false);
  const [selectedNotes, setSelectedNotes] = useState<Set<string>>(new Set());
  const [isSelectionMode, setIsSelectionMode] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    field: 'lastModified',
    order: 'desc'
  });

  const activeFolder = useMemo(() => {
    return folders.find(f => f.id === activeFolderId);
  }, [folders, activeFolderId]);

  const folderNotes = useMemo(() => {
    let filteredNotes = notes;

    // Filter notes based on folder
    if (activeFolderId === 'uncategorized') {
      filteredNotes = notes.filter(note => !note.folderId);
    } else if (activeFolderId && activeFolderId !== 'all') {
      filteredNotes = notes.filter(note => note.folderId === activeFolderId);
    }
    
    // Apply search filter
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      filteredNotes = filteredNotes.filter(note => 
        note.title.toLowerCase().includes(query) || 
        note.content.toLowerCase().includes(query)
      );
    }

    // Sort notes
    return filteredNotes.sort((a, b) => {
      const { field, order } = sortConfig;
      const multiplier = order === 'asc' ? 1 : -1;
      
      if (field === 'title') {
        return multiplier * a.title.localeCompare(b.title);
      }
      return multiplier * (b.lastModified - a.lastModified);
    });
  }, [notes, activeFolderId, searchQuery, sortConfig]);

  const handleSort = (field: SortField) => {
    setSortConfig(prev => ({
      field,
      order: prev.field === field && prev.order === 'asc' ? 'desc' : 'asc'
    }));
  };

  const toggleNoteSelection = (noteId: string, event: React.MouseEvent) => {
    event.stopPropagation();
    setSelectedNotes(prev => {
      const next = new Set(prev);
      if (next.has(noteId)) {
        next.delete(noteId);
      } else {
        next.add(noteId);
      }
      return next;
    });
  };

  const toggleSelectAll = () => {
    if (selectedNotes.size === folderNotes.length) {
      setSelectedNotes(new Set());
    } else {
      setSelectedNotes(new Set(folderNotes.map(note => note.id)));
    }
  };

  const handleBulkDelete = async () => {
    try {
      for (const noteId of selectedNotes) {
        await deleteNote(noteId);
      }
      setSelectedNotes(new Set());
      setIsSelectionMode(false);
      setShowDeleteConfirm(false);
    } catch (error) {
      console.error('Failed to delete notes:', error);
    }
  };

  const exitSelectionMode = () => {
    setIsSelectionMode(false);
    setSelectedNotes(new Set());
  };

  const getFolderName = () => {
    if (activeFolderId === 'all') return 'All Notes';
    if (activeFolderId === 'uncategorized') return 'Uncategorized';
    return activeFolder?.name || 'Notes';
  };

  const renderNoteCard = (note: Note) => {
    const preview = note.content
      .replace(/<[^>]+>/g, ' ')
      .slice(0, 100)
      .trim();

    const isSelected = selectedNotes.has(note.id);
    const noteFolder = folders.find(f => f.id === note.folderId);

    return (
      <div
        key={note.id}
        onClick={(e) => isSelectionMode ? toggleNoteSelection(note.id, e) : setActiveNote(note.id)}
        className={`group cursor-pointer ${isSelected ? 'ring-2 ring-indigo-500' : ''}`}
      >
        {viewMode === 'grid' ? (
          <div className="bg-white dark:bg-gray-800 rounded-lg p-4 hover:shadow-md transition-shadow relative">
            {isSelectionMode && (
              <div className="absolute top-2 right-2">
                {isSelected ? (
                  <CheckSquare className="w-5 h-5 text-indigo-500" />
                ) : (
                  <Square className="w-5 h-5 text-gray-400" />
                )}
              </div>
            )}
            <h3 className="font-medium text-gray-900 dark:text-white mb-2 truncate pr-8">
              {note.title || 'Untitled Note'}
            </h3>
            <p className="text-sm text-gray-500 dark:text-gray-400 line-clamp-3">
              {preview || 'No content'}
            </p>
            <div className="mt-2 flex items-center justify-between text-xs">
              <span className="text-gray-400 dark:text-gray-500">
                {new Date(note.lastModified).toLocaleDateString()}
              </span>
              {activeFolderId === 'all' && noteFolder && (
                <span className="text-indigo-500 dark:text-indigo-400">
                  {noteFolder.name}
                </span>
              )}
            </div>
          </div>
        ) : (
          <div className="flex items-center gap-3 p-3 hover:bg-gray-50 dark:hover:bg-gray-800 rounded-lg">
            {isSelectionMode ? (
              <div className="flex-shrink-0">
                {isSelected ? (
                  <CheckSquare className="w-5 h-5 text-indigo-500" />
                ) : (
                  <Square className="w-5 h-5 text-gray-400" />
                )}
              </div>
            ) : (
              <FileText className="w-5 h-5 text-gray-400 dark:text-gray-500 flex-shrink-0" />
            )}
            <div className="flex-1 min-w-0">
              <h3 className="font-medium text-gray-900 dark:text-white truncate">
                {note.title || 'Untitled Note'}
              </h3>
              <p className="text-sm text-gray-500 dark:text-gray-400 truncate">
                {preview || 'No content'}
              </p>
            </div>
            <div className="text-right flex-shrink-0">
              <div className="text-xs text-gray-400 dark:text-gray-500">
                {new Date(note.lastModified).toLocaleDateString()}
              </div>
              {activeFolderId === 'all' && noteFolder && (
                <div className="text-xs text-indigo-500 dark:text-indigo-400 mt-1">
                  {noteFolder.name}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="flex-1 flex flex-col h-full bg-gray-50 dark:bg-black overflow-hidden">
      <div className="border-b border-gray-200 dark:border-gray-800 bg-white dark:bg-black">
        <div className="px-3 sm:px-4 py-3 sm:py-4">
          <div className="flex items-center justify-between mb-3 sm:mb-4">
            <div className="flex items-center gap-2">
              {isSelectionMode ? (
                <>
                  <button
                    onClick={exitSelectionMode}
                    className="p-1 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg"
                  >
                    <X size={20} className="text-gray-500" />
                  </button>
                  <span className="text-sm font-medium text-gray-600 dark:text-gray-300">
                    {selectedNotes.size} selected
                  </span>
                </>
              ) : (
                <>
                  <h1 className="text-lg sm:text-xl font-semibold text-gray-900 dark:text-white truncate">
                    {getFolderName()}
                  </h1>
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    ({folderNotes.length})
                  </span>
                </>
              )}
            </div>
            <div className="flex items-center gap-2">
              {isSelectionMode ? (
                <>
                  <button
                    onClick={toggleSelectAll}
                    className="text-sm text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
                  >
                    {selectedNotes.size === folderNotes.length ? 'Deselect All' : 'Select All'}
                  </button>
                  <button
                    onClick={() => setShowDeleteConfirm(true)}
                    disabled={selectedNotes.size === 0}
                    className="flex items-center gap-2 bg-red-600 text-white px-3 py-2 rounded-lg hover:bg-red-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <Trash2 size={18} />
                    <span className="hidden sm:inline">Delete Selected</span>
                  </button>
                </>
              ) : (
                <>
                  {folderNotes.length > 0 && (
                    <button
                      onClick={() => setIsSelectionMode(true)}
                      className="p-2 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg"
                      title="Select notes"
                    >
                      <Square size={18} className="text-gray-600 dark:text-gray-300" />
                    </button>
                  )}
                  <button
                    onClick={() => addNote(activeFolderId === 'all' ? null : activeFolderId)}
                    className="flex items-center gap-2 bg-indigo-600 text-white px-3 sm:px-4 py-2 rounded-lg hover:bg-indigo-700 transition-colors"
                  >
                    <Plus size={18} />
                    <span className="hidden sm:inline">New Note</span>
                  </button>
                </>
              )}
            </div>
          </div>
          
          {!isSelectionMode && (
            <div className="flex flex-col gap-3">
              <div className="flex gap-2">
                <div className="relative flex-1">
                  <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                  <input
                    type="text"
                    placeholder="Search notes..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full pl-10 pr-4 py-2 bg-gray-50 dark:bg-gray-900 border border-gray-200 dark:border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-sm"
                  />
                </div>
                <button
                  onClick={() => setShowFilters(!showFilters)}
                  className="p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-800 sm:hidden"
                  title="Show filters"
                >
                  <Filter size={18} className="text-gray-600 dark:text-gray-300" />
                </button>
              </div>
              
              <div className={`flex items-center gap-2 ${showFilters ? 'flex' : 'hidden sm:flex'}`}>
                <button
                  onClick={() => handleSort('lastModified')}
                  className={`p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-800 ${
                    sortConfig.field === 'lastModified' ? 'bg-gray-100 dark:bg-gray-800' : ''
                  }`}
                  title="Sort by date"
                >
                  {sortConfig.field === 'lastModified' && sortConfig.order === 'asc' ? (
                    <SortAsc size={18} className="text-gray-600 dark:text-gray-300" />
                  ) : (
                    <SortDesc size={18} className="text-gray-600 dark:text-gray-300" />
                  )}
                </button>
                <button
                  onClick={() => handleSort('title')}
                  className={`p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-800 ${
                    sortConfig.field === 'title' ? 'bg-gray-100 dark:bg-gray-800' : ''
                  }`}
                  title="Sort by title"
                >
                  {sortConfig.field === 'title' && sortConfig.order === 'asc' ? (
                    <SortAsc size={18} className="text-gray-600 dark:text-gray-300" />
                  ) : (
                    <SortDesc size={18} className="text-gray-600 dark:text-gray-300" />
                  )}
                </button>
                <div className="border-l border-gray-200 dark:border-gray-700 h-6 mx-1" />
                <button
                  onClick={() => setViewMode('grid')}
                  className={`p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-800 ${
                    viewMode === 'grid' ? 'bg-gray-100 dark:bg-gray-800' : ''
                  }`}
                  title="Grid view"
                >
                  <Grid size={18} className="text-gray-600 dark:text-gray-300" />
                </button>
                <button
                  onClick={() => setViewMode('list')}
                  className={`p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-800 ${
                    viewMode === 'list' ? 'bg-gray-100 dark:bg-gray-800' : ''
                  }`}
                  title="List view"
                >
                  <List size={18} className="text-gray-600 dark:text-gray-300" />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="flex-1 overflow-y-auto p-3 sm:p-4">
        {folderNotes.length === 0 ? (
          <div className="flex flex-col items-center justify-center h-full text-center p-4">
            <img 
              src="/meranotes.png" 
              alt="MeraNotes Logo" 
              className="w-16 sm:w-20 h-16 sm:h-20 mb-4 opacity-50"
            />
            <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
              No notes found
            </h3>
            <p className="text-sm text-gray-500 dark:text-gray-400 mb-4">
              Create a new note to get started
            </p>
            <button
              onClick={() => addNote(activeFolderId === 'all' ? null : activeFolderId)}
              className="flex items-center gap-2 bg-indigo-600 text-white px-4 py-2 rounded-lg hover:bg-indigo-700 transition-colors"
            >
              <Plus size={18} />
              Create Note
            </button>
          </div>
        ) : (
          <div className={
            viewMode === 'grid' 
              ? 'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 sm:gap-4'
              : 'space-y-2'
          }>
            {folderNotes.map(renderNoteCard)}
          </div>
        )}
      </div>

      <ConfirmDialog
        isOpen={showDeleteConfirm}
        title="Delete Notes"
        message={`Are you sure you want to delete ${selectedNotes.size} selected note${selectedNotes.size === 1 ? '' : 's'}? This action cannot be undone.`}
        onConfirm={handleBulkDelete}
        onCancel={() => setShowDeleteConfirm(false)}
      />
    </div>
  );
}