import { Note } from '../types/note';
import { Folder } from '../types/folder';
import { storage } from './storage/database';
import { createMeraNotesFile, parseMeraNotesFile, downloadMeraNotesFile } from './storage/fileFormat';

export async function exportNotes(notes: Note[], folders: Folder[]): Promise<void> {
  try {
    const blob = await createMeraNotesFile(notes, folders);
    const filename = `meranotes-${new Date().toISOString().split('T')[0]}.meranotes`;
    downloadMeraNotesFile(blob, filename);
  } catch (error) {
    console.error('Failed to export notes:', error);
    throw new Error('Failed to export notes. Please try again.');
  }
}

export async function importNotes(file: File): Promise<{ notes: Note[], folders: Folder[] }> {
  try {
    const data = await parseMeraNotesFile(file);
    
    // Save imported data
    await Promise.all([
      ...data.notes.map(note => storage.saveNote(note)),
      ...data.folders.map(folder => storage.saveFolder(folder))
    ]);

    return {
      notes: data.notes,
      folders: data.folders
    };
  } catch (error) {
    console.error('Failed to import notes:', error);
    throw new Error('Failed to import notes. Please check the file format.');
  }
}