import React from 'react';
import { MessageCircle, Mail } from 'lucide-react';
import { Features } from './Features';

export function Content() {
  return (
    <div className="space-y-16">
      {/* Hero Section */}
      <div className="text-center">
        <h1 className="text-4xl font-bold bg-gradient-to-r from-indigo-600 to-pink-600 bg-clip-text text-transparent mb-4">
          San Juniper AI Labs
        </h1>
        <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
          Building the future of privacy-focused AI applications
        </p>
      </div>

      {/* Features */}
      <Features />

      {/* Vision Section */}
      <section className="bg-gray-50 dark:bg-gray-800/50 rounded-2xl p-8">
        <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">Our Vision</h2>
        <p className="text-gray-600 dark:text-gray-300">
          We believe in a future where technology empowers individuals while preserving their privacy 
          and autonomy. Our applications are built with this vision in mind, ensuring that user data 
          remains under their control.
        </p>
      </section>

      {/* Contact Section */}
      <section className="text-center">
        <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">Get in Touch</h2>
        <div className="flex flex-col sm:flex-row gap-4 justify-center">
          <a
            href="https://wa.me/+917021489941"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center justify-center gap-2 px-6 py-3 bg-[#25D366] text-white rounded-xl hover:bg-[#128C7E] transition-colors"
          >
            <MessageCircle className="w-5 h-5" />
            Chat on WhatsApp
          </a>
          <a
            href="mailto:meranotes@sanjuniper.com"
            className="inline-flex items-center justify-center gap-2 px-6 py-3 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-xl hover:from-indigo-700 hover:to-purple-700 transition-colors"
          >
            <Mail className="w-5 h-5" />
            Email Us
          </a>
        </div>
      </section>
    </div>
  );
}