import { useState } from 'react';
import { shareNote } from '../utils/supabase/storage';
import { Note } from '../types/note';
import { ShareResult } from '../types/share';

export function useShare() {
  const [isSharing, setIsSharing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [shareUrl, setShareUrl] = useState<string | null>(null);

  const handleShare = async (note: Note): Promise<ShareResult> => {
    try {
      setIsSharing(true);
      setError(null);

      const result = await shareNote(note);
      
      if (result.success && result.url) {
        setShareUrl(result.url);
        return result;
      } else {
        throw new Error(result.error || 'Failed to share note');
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to share note';
      setError(errorMessage);
      return { success: false, error: errorMessage };
    } finally {
      setIsSharing(false);
    }
  };

  return {
    isSharing,
    error,
    shareUrl,
    handleShare,
    clearError: () => setError(null)
  };
}