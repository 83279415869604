import { IDBPDatabase, IDBPTransaction } from 'idb';
import { Note } from '../../types/note';

export async function executeTransaction<T>(
  db: IDBPDatabase,
  storeName: string,
  mode: IDBTransactionMode,
  operation: (store: IDBPTransaction<unknown, [string], string>['store']) => Promise<T>
): Promise<T> {
  const tx = db.transaction(storeName, mode);
  try {
    const result = await operation(tx.store);
    await tx.done;
    return result;
  } catch (error) {
    await tx.abort();
    throw error;
  }
}

export async function saveNoteTransaction(
  db: IDBPDatabase,
  note: Note
): Promise<void> {
  await executeTransaction(db, 'notes', 'readwrite', async (store) => {
    await store.put(note);
  });
}

export async function deleteNoteTransaction(
  db: IDBPDatabase,
  id: string
): Promise<void> {
  await executeTransaction(db, 'notes', 'readwrite', async (store) => {
    const note = await store.get(id);
    if (note) {
      await store.put({ ...note, deleted: true, lastModified: Date.now() });
    }
  });
}

export async function getAllNotesTransaction(
  db: IDBPDatabase
): Promise<Note[]> {
  return executeTransaction(db, 'notes', 'readonly', async (store) => {
    return store.getAll();
  });
}