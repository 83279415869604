import { z } from 'zod';

export const STORAGE_CONFIG = {
  BUCKET_NAME: import.meta.env.VITE_SUPABASE_BUCKET_NAME || 'meranotes-files',
  MAX_FILE_SIZE: 10 * 1024 * 1024, // 10MB
  EXPIRY_DAYS: 7,
  ALLOWED_MIME_TYPES: ['application/x-meranotes', 'application/json'],
  SHARE_BASE_URL: 'https://meranotes.com'
} as const;

export const ERRORS = {
  FILE_TOO_LARGE: 'File size exceeds 10MB limit',
  INVALID_FILE_TYPE: 'Invalid file type',
  UPLOAD_FAILED: 'Failed to upload file',
  DOWNLOAD_FAILED: 'Failed to download file',
  EXPIRED: 'This shared note has expired',
  NOT_FOUND: 'Shared note not found',
  INVALID_SHARE_URL: 'Invalid share URL'
} as const;