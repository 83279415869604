import React from 'react';
import { Shield, Sparkles, Wifi, Share2 } from 'lucide-react';

const features = [
  {
    icon: Shield,
    title: 'Privacy-First Design',
    description: 'Your notes stay local - no cloud storage, no tracking, just pure note-taking.',
    gradient: 'from-blue-500 to-cyan-500'
  },
  {
    icon: Wifi,
    title: 'Works Offline',
    description: 'Full functionality without internet connection. Perfect for anywhere, anytime note-taking.',
    gradient: 'from-green-500 to-emerald-500'
  },
  {
    icon: Sparkles,
    title: 'AI-Powered',
    description: 'Generate structured content with OpenAI integration for enhanced productivity.',
    gradient: 'from-purple-500 to-pink-500'
  },
  {
    icon: Share2,
    title: 'Secure Sharing',
    description: 'Share notes with expiring links while maintaining privacy and control.',
    gradient: 'from-orange-500 to-red-500'
  }
];

export function Features() {
  return (
    <section className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {features.map((feature, index) => {
        const Icon = feature.icon;
        return (
          <div 
            key={index}
            className="group relative bg-white dark:bg-gray-800 rounded-2xl p-6 shadow-lg hover:shadow-xl transition-all duration-300"
          >
            <div className="absolute inset-0 bg-gradient-to-br opacity-0 group-hover:opacity-5 dark:group-hover:opacity-10 rounded-2xl transition-opacity" />
            
            <div className={`inline-flex p-3 rounded-xl bg-gradient-to-br ${feature.gradient} mb-4`}>
              <Icon className="w-6 h-6 text-white" />
            </div>
            
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
              {feature.title}
            </h3>
            
            <p className="text-gray-600 dark:text-gray-300">
              {feature.description}
            </p>
          </div>
        );
      })}
    </section>
  );
}