import { STORAGE_CONFIG } from './supabase/config';

export function transformShareUrl(supabaseUrl: string): string {
  try {
    const url = new URL(supabaseUrl);
    const pathParts = url.pathname.split('/');
    const filename = pathParts[pathParts.length - 1];
    const shareId = filename.replace('.meranotes', '');
    
    return `${STORAGE_CONFIG.SHARE_BASE_URL}/share/${shareId}`;
  } catch (error) {
    console.error('Failed to transform URL:', error);
    return supabaseUrl;
  }
}

export function getShareIdFromUrl(url: string): string | null {
  try {
    const urlObj = new URL(url);
    if (!urlObj.hostname.endsWith('meranotes.com')) {
      return null;
    }
    const pathParts = urlObj.pathname.split('/');
    return pathParts[pathParts.length - 1] || null;
  } catch (error) {
    console.error('Failed to get share ID from URL:', error);
    return null;
  }
}

export function isValidShareUrl(url: string): boolean {
  try {
    const urlObj = new URL(url);
    return (
      urlObj.protocol === 'https:' &&
      urlObj.hostname === 'meranotes.com' &&
      urlObj.pathname.startsWith('/share/') &&
      urlObj.pathname.length > 7
    );
  } catch {
    return false;
  }
}