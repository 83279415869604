import StarterKit from '@tiptap/starter-kit';
import Heading from '@tiptap/extension-heading';
import Link from '@tiptap/extension-link';
import Underline from '@tiptap/extension-underline';
import Superscript from '@tiptap/extension-superscript';
import Subscript from '@tiptap/extension-subscript';
import TextAlign from '@tiptap/extension-text-align';
import Highlight from '@tiptap/extension-highlight';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableHeader from '@tiptap/extension-table-header';
import TableCell from '@tiptap/extension-table-cell';
import TaskList from '@tiptap/extension-task-list';
import TaskItem from '@tiptap/extension-task-item';
import Placeholder from '@tiptap/extension-placeholder';
import { markdownExtension } from './markdown';

export const editorExtensions = [
  StarterKit.configure({
    heading: {
      levels: [1, 2, 3]
    },
    history: true,
    codeBlock: true,
    blockquote: true,
    bulletList: true,
    orderedList: true,
    bold: true,
    italic: true,
    strike: true,
    code: true,
  }),
  markdownExtension,
  Link.configure({
    openOnClick: false,
  }),
  Underline,
  Superscript,
  Subscript,
  TextAlign.configure({
    types: ['heading', 'paragraph'],
    alignments: ['left', 'center', 'right'],
  }),
  Highlight,
  Table.configure({
    resizable: true,
  }),
  TableRow,
  TableHeader,
  TableCell,
  TaskList,
  TaskItem.configure({
    nested: true,
  }),
  Placeholder.configure({
    placeholder: 'Start writing your note here...',
  }),
];