import React, { useState } from 'react';
import { Editor } from '@tiptap/react';
import {
  Bold, Italic, Underline, Strikethrough, Superscript, Subscript,
  List, ListOrdered, Link, Quote, Code, Table,
  Heading1, Heading2, Heading3, CheckSquare,
  Highlighter, AlignLeft, AlignCenter, AlignRight,
  Undo, Redo
} from 'lucide-react';
import { useResponsive } from '../../hooks/useResponsive';

interface EditorToolbarProps {
  editor: Editor;
}

export function EditorToolbar({ editor }: EditorToolbarProps) {
  const [showMore, setShowMore] = useState(false);
  const { isMobile } = useResponsive();

  const primaryTools = [
    { icon: Bold, action: () => editor.chain().focus().toggleBold().run() },
    { icon: Italic, action: () => editor.chain().focus().toggleItalic().run() },
    { icon: Underline, action: () => editor.chain().focus().toggleUnderline().run() },
    { icon: Strikethrough, action: () => editor.chain().focus().toggleStrike().run() },
    { icon: List, action: () => editor.chain().focus().toggleBulletList().run() },
    { icon: Code, action: () => editor.chain().focus().toggleCode().run() }
  ];

  const secondaryTools = [
    { icon: Heading1, action: () => editor.chain().focus().toggleHeading({ level: 1 }).run() },
    { icon: Heading2, action: () => editor.chain().focus().toggleHeading({ level: 2 }).run() },
    { icon: Heading3, action: () => editor.chain().focus().toggleHeading({ level: 3 }).run() },
    { icon: ListOrdered, action: () => editor.chain().focus().toggleOrderedList().run() },
    { icon: Quote, action: () => editor.chain().focus().toggleBlockquote().run() },
    { icon: Table, action: () => editor.chain().focus().insertTable().run() }
  ];
  
  const alignmentTools = [
    { icon: AlignLeft, action: () => editor.chain().focus().setTextAlign('left').run() },
    { icon: AlignCenter, action: () => editor.chain().focus().setTextAlign('center').run() },
    { icon: AlignRight, action: () => editor.chain().focus().setTextAlign('right').run() }
  ];

  const specialTools = [
    { icon: Superscript, action: () => editor.chain().focus().toggleSuperscript().run() },
    { icon: Subscript, action: () => editor.chain().focus().toggleSubscript().run() },
    { icon: Highlighter, action: () => editor.chain().focus().toggleHighlight().run() }
  ];

  return (
    <div className="border-b border-gray-200 dark:border-gray-800 p-2 flex flex-col gap-2 relative">
      <div className="flex gap-1">
        {primaryTools.map((tool, index) => (
          <button
            key={index}
            onClick={tool.action}
            className="p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors"
            title={tool.icon.name}
          >
            <tool.icon size={isMobile ? 16 : 18} />
          </button>
        ))}
        
        <div className="w-px h-6 my-auto mx-1 bg-gray-200 dark:bg-gray-700" />
        
        {secondaryTools.map((tool, index) => (
          <button
            key={index}
            onClick={tool.action}
            className="p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors"
            title={tool.icon.name}
          >
            <tool.icon size={isMobile ? 16 : 18} />
          </button>
        ))}
      </div>

      <div className="flex gap-1">
        {alignmentTools.map((tool, index) => (
          <button
            key={index}
            onClick={tool.action}
            className="p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors"
            title={tool.icon.name}
          >
            <tool.icon size={isMobile ? 16 : 18} />
          </button>
        ))}

        <div className="w-px h-6 my-auto mx-1 bg-gray-200 dark:bg-gray-700" />

        {specialTools.map((tool, index) => (
          <button
            key={index}
            onClick={tool.action}
            className="p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors"
            title={tool.icon.name}
          >
            <tool.icon size={isMobile ? 16 : 18} />
          </button>
        ))}
      </div>
    </div>
  );
}