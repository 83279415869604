import React, { useState } from 'react';
import { Share2 } from 'lucide-react';
import { Note } from '../../types/note';
import { ShareDialog } from './ShareDialog';

interface ShareButtonProps {
  note: Note;
}

export function ShareButton({ note }: ShareButtonProps) {
  const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      <button
        onClick={() => setShowDialog(true)}
        className="p-2 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg transition-colors"
        title="Share note"
      >
        <Share2 size={20} className="text-gray-500 dark:text-gray-400" />
      </button>

      <ShareDialog
        isOpen={showDialog}
        onClose={() => setShowDialog(false)}
        note={note}
      />
    </>
  );
}