import { storage } from '../storage/database';
import { useNotesStore } from '../../store/useNotesStore';
import { useFoldersStore } from '../../store/useFoldersStore';

export async function initializeEnvironment(): Promise<boolean> {
  try {
    // Check if storage is available and initialized
    const isAvailable = await storage.checkStorageAvailability();
    if (!isAvailable) {
      throw new Error('Storage not available');
    }

    // Initialize stores if needed
    const notesStore = useNotesStore.getState();
    const foldersStore = useFoldersStore.getState();

    if (!notesStore.isInitialized) {
      await notesStore.initializeNotes();
    }

    // Ensure imported folder exists
    foldersStore.ensureImportedFolder();

    return true;
  } catch (error) {
    console.error('Failed to initialize environment:', error);
    return false;
  }
}