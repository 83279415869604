import { Extension } from '@tiptap/core';
import { Plugin } from '@tiptap/pm/state';
import { InputRule } from '@tiptap/core';

export const markdownExtension = Extension.create({
  name: 'markdown',

  addInputRules() {
    return [
      // Headings
      new InputRule({
        find: /^(#{1,3})\s(.+)$/,
        handler: ({ match, editor }) => {
          const level = match[1].length;
          const content = match[2];
          editor.chain()
            .setHeading({ level })
            .insertContent(content)
            .run();
        },
      }),

      // Lists
      new InputRule({
        find: /^[-*]\s(.+)$/,
        handler: ({ match, editor }) => {
          const content = match[1];
          editor.chain()
            .toggleBulletList()
            .insertContent(content)
            .run();
        },
      }),

      new InputRule({
        find: /^\d+\.\s(.+)$/,
        handler: ({ match, editor }) => {
          const content = match[1];
          editor.chain()
            .toggleOrderedList()
            .insertContent(content)
            .run();
        },
      }),

      // Blockquotes
      new InputRule({
        find: /^>\s(.+)$/,
        handler: ({ match, editor }) => {
          const content = match[1];
          editor.chain()
            .toggleBlockquote()
            .insertContent(content)
            .run();
        },
      }),

      // Code blocks
      new InputRule({
        find: /^```(.*)$/,
        handler: ({ editor }) => {
          editor.commands.toggleCodeBlock();
        },
      }),

      // Bold
      new InputRule({
        find: /\*\*([^*]+)\*\*$/,
        handler: ({ match, editor }) => {
          const content = match[1];
          editor.chain()
            .toggleBold()
            .insertContent(content)
            .run();
        },
      }),

      // Italic
      new InputRule({
        find: /_([^_]+)_$/,
        handler: ({ match, editor }) => {
          const content = match[1];
          editor.chain()
            .toggleItalic()
            .insertContent(content)
            .run();
        },
      }),
    ];
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          handlePaste: (view, event) => {
            const text = event.clipboardData?.getData('text/plain');
            if (!text) return false;

            const lines = text.split('\n');
            const tr = view.state.tr;
            const pos = view.state.selection.from;

            lines.forEach((line, index) => {
              // Add newline between lines
              if (index > 0) {
                tr.insert(tr.mapping.map(pos + index), view.state.schema.text('\n'));
              }

              // Handle markdown formatting
              let content = line;

              // Headings
              content = content.replace(/^(#{1,3})\s(.+)$/, (_, level, text) => {
                tr.setBlockType(pos, pos + content.length, view.state.schema.nodes.heading, { level: level.length });
                return text;
              });

              // Lists
              content = content.replace(/^[-*]\s(.+)$/, (_, text) => {
                tr.setBlockType(pos, pos + content.length, view.state.schema.nodes.bulletList);
                return text;
              });

              // Blockquotes
              content = content.replace(/^>\s(.+)$/, (_, text) => {
                tr.setBlockType(pos, pos + content.length, view.state.schema.nodes.blockquote);
                return text;
              });

              // Bold
              content = content.replace(/\*\*([^*]+)\*\*/g, (_, text) => {
                return text;
              });

              // Italic
              content = content.replace(/_([^_]+)_/g, (_, text) => {
                return text;
              });

              tr.insert(tr.mapping.map(pos + index), view.state.schema.text(content));
            });

            view.dispatch(tr);
            return true;
          },
        },
      }),
    ];
  },
});