import { useState, useCallback } from 'react';
import { storageManager } from '../utils/storage/realtime/manager';

export function useStorage() {
  const [updateStatus, setUpdateStatus] = useState<string>('idle');
  const [error, setError] = useState<string | null>(null);

  const updateContent = useCallback(async (id: string, content: string) => {
    try {
      setError(null);
      setUpdateStatus('saving');
      await storageManager.updateContent(id, content);
      setUpdateStatus('saved');

      // Reset status after 2 seconds
      setTimeout(() => {
        setUpdateStatus('idle');
      }, 2000);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to save content');
      setUpdateStatus('error');
    }
  }, []);

  return {
    updateContent,
    updateStatus,
    error
  };
}