import React, { useState, useEffect, useRef } from 'react';
import { Copy, FolderIcon, ArrowLeft } from 'lucide-react';
import { Note } from '../../types/note';
import { Folder } from '../../types/folder';
import { ShareButton } from '../share/ShareButton';
import { useNavigate } from '../../hooks/useNavigate';

interface EditorHeaderProps {
  note: Note;
  folders: Folder[];
  isSidebarVisible: boolean;
  onUpdateNote: (id: string, updates: Partial<Note>) => void;
  onCopy: () => void;
}

export function EditorHeader({ note, folders, isSidebarVisible, onUpdateNote, onCopy }: EditorHeaderProps) {
  const { navigateToFolder } = useNavigate();

  const [isEditing, setIsEditing] = useState(false);
  const [titleValue, setTitleValue] = useState(note.title);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setTitleValue(note.title);
  }, [note.title]);

  const handleFocus = () => {
    setIsEditing(true);
    setIsFocused(true);
    if (titleValue === 'Untitled Note') {
      setTitleValue('');
    }
  };

  const handleBlur = () => {
    setIsEditing(false);
    setIsFocused(false);
    const newTitle = titleValue.trim() || 'Untitled Note';
    setTitleValue(newTitle);
    if (newTitle !== note.title) {
      onUpdateNote(note.id, { title: newTitle });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitleValue(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      inputRef.current?.blur();
    }
  };

  return (
    <div className="border-b border-gray-200 dark:border-gray-800 p-4">
      <div className="flex flex-col gap-4 sm:flex-row sm:items-center">
        {!isSidebarVisible && (
          <button
            onClick={() => navigateToFolder(note.folderId)}
            className="inline-flex items-center gap-2 text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white sm:hidden"
          >
            <ArrowLeft size={20} />
            <span>Back</span>
          </button>
        )}
        
        <div className="flex-1 min-w-0">
          <input
            ref={inputRef}
            type="text"
            value={titleValue}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            className={`w-full text-xl font-semibold bg-transparent border-none focus:outline-none dark:text-white overflow-hidden text-ellipsis
              ${!isEditing && titleValue === 'Untitled Note' ? 'text-gray-400 dark:text-gray-500 transition-colors duration-200' : ''}
              ${isFocused ? 'placeholder-transparent' : ''}`}
            placeholder="Note title"
          />
        </div>

        <div className="flex items-center gap-2 flex-shrink-0">
          <div className="flex items-center gap-2">
            <FolderIcon size={16} className="text-gray-400 dark:text-gray-500" />
            <select
              value={note.folderId || ''}
              onChange={(e) => onUpdateNote(note.id, { folderId: e.target.value || null })}
              className="text-sm border-none bg-transparent focus:outline-none focus:ring-2 focus:ring-indigo-500 rounded dark:text-white max-w-[120px] sm:max-w-none"
            >
              <option value="">No folder</option>
              {folders.map((folder) => (
                <option key={folder.id} value={folder.id}>
                  {folder.name}
                </option>
              ))}
            </select>
          </div>
          <ShareButton note={note} />
          <button
            onClick={onCopy}
            className="p-2 hover:bg-gray-100 dark:hover:bg-gray-900 rounded-lg transition-colors"
            title="Copy to clipboard"
          >
            <Copy size={20} className="text-gray-500 dark:text-gray-400" />
          </button>
        </div>
      </div>
    </div>
  );
}