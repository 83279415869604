import * as LZString from 'lz-string';

export const compressData = (data: string): string => {
  try {
    return LZString.compressToUTF16(data);
  } catch (error) {
    console.error('Compression failed:', error);
    return data;
  }
};

export const decompressData = (data: string): string => {
  if (!data) return '';
  try {
    const decompressed = LZString.decompressFromUTF16(data);
    return decompressed || data;
  } catch (error) {
    console.error('Decompression failed:', error);
    return data;
  }
};