import { z } from 'zod';

export const AVAILABLE_CONTENT_TYPES = [
  'Article',
  'Blog Post',
  'Tutorial',
  'Guide',
  'Documentation'
] as const;

export const AVAILABLE_TONES = [
  'professional',
  'casual',
  'technical',
  'conversational'
] as const;

interface AIConfig {
  title: string;
  description: string;
  contentType: typeof AVAILABLE_CONTENT_TYPES[number];
  tone: typeof AVAILABLE_TONES[number];
  targetAudience: string;
  keyPoints: string[];
}

export async function verifyOpenAIKey(apiKey: string): Promise<boolean> {
  try {
    const response = await fetch('https://api.openai.com/v1/models', {
      headers: {
        'Authorization': `Bearer ${apiKey}`,
        'Content-Type': 'application/json',
      },
    });
    return response.status === 200;
  } catch (error) {
    console.error('Failed to verify OpenAI API key:', error);
    return false;
  }
}

export async function generateSuggestions(title: string, apiKey: string) {
  try {
    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${apiKey}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        model: 'gpt-3.5-turbo',
        messages: [
          {
            role: 'system',
            content: 'You are a helpful assistant that suggests content structure. Respond in JSON format only.'
          },
          {
            role: 'user',
            content: `Generate content suggestions for a note titled "${title}". Include description, contentType (one of: ${AVAILABLE_CONTENT_TYPES.join(', ')}), tone (one of: ${AVAILABLE_TONES.join(', ')}), targetAudience, and keyPoints (array of 3-5 points).`
          }
        ],
        temperature: 0.7,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to generate suggestions');
    }

    const data = await response.json();
    const content = data.choices[0]?.message?.content;
    
    if (!content) {
      throw new Error('No suggestions generated');
    }

    const suggestions = JSON.parse(content);
    return {
      description: suggestions.description || '',
      contentType: suggestions.contentType || AVAILABLE_CONTENT_TYPES[0],
      tone: suggestions.tone || AVAILABLE_TONES[0],
      targetAudience: suggestions.targetAudience || '',
      keyPoints: suggestions.keyPoints || [''],
    };
  } catch (error) {
    console.error('Failed to generate suggestions:', error);
    throw new Error('Failed to generate suggestions');
  }
}

export async function generateNoteContent(config: AIConfig, apiKey: string): Promise<string> {
  const prompt = `
    Create a ${config.contentType.toLowerCase()} about "${config.title}"
    
    Instructions:
    - Use a ${config.tone} tone
    - Target audience: ${config.targetAudience}
    - Follow this description: ${config.description}
    
    Key Points to Cover:
    ${config.keyPoints.filter(point => point.trim()).map(point => `- ${point}`).join('\n')}
    
    Create a detailed response in 500 words or above. Format the response in clean HTML with proper semantic tags (h1, h2, p, ul, etc).
  `;

  try {
    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${apiKey}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        model: 'gpt-3.5-turbo',
        messages: [
          {
            role: 'system',
            content: 'You are a professional content writer. Create well-structured content in clean HTML format.'
          },
          {
            role: 'user',
            content: prompt
          }
        ],
        temperature: 0.7,
        max_tokens: 2000,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to generate content');
    }

    const data = await response.json();
    const content = data.choices[0]?.message?.content;

    if (!content) {
      throw new Error('No content generated');
    }

    return content.includes('<') ? content : `<p>${content}</p>`;
  } catch (error) {
    console.error('Failed to generate content:', error);
    throw new Error('Failed to generate content. Please try again.');
  }
}