import { isValidShareUrl } from '../url';
import { checkFileExists } from '../supabase/storage';
import { ERRORS } from '../supabase/config';

export interface ValidationResult {
  isValid: boolean;
  error?: string;
}

export async function validateShareLink(url: string): Promise<ValidationResult> {
  try {
    // Validate URL format
    if (!isValidShareUrl(url)) {
      return { isValid: false, error: ERRORS.INVALID_SHARE_URL };
    }

    // Extract shareId from URL
    const shareId = url.split('/').pop();
    if (!shareId) {
      return { isValid: false, error: ERRORS.INVALID_SHARE_URL };
    }

    // Check if file exists in storage
    const exists = await checkFileExists(shareId);
    if (!exists) {
      return { isValid: false, error: ERRORS.NOT_FOUND };
    }

    return { isValid: true };
  } catch (error) {
    return { 
      isValid: false, 
      error: error instanceof Error ? error.message : ERRORS.INVALID_SHARE_URL 
    };
  }
}