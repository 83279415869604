import { Note } from '../../types/note';
import { Folder } from '../../types/folder';
import { compressData, decompressData } from './compression';

interface MeraNotesFile {
  version: number;
  timestamp: number;
  notes: Note[];
  folders: Folder[];
  metadata: {
    totalNotes: number;
    totalFolders: number;
    exportDate: string;
    appVersion: string;
  };
}

const CURRENT_VERSION = 1;
const FILE_SIGNATURE = 'MERANOTES';
const MIME_TYPE = 'application/x-meranotes';

export async function createMeraNotesFile(notes: Note[], folders: Folder[]): Promise<Blob> {
  const data: MeraNotesFile = {
    version: CURRENT_VERSION,
    timestamp: Date.now(),
    notes,
    folders,
    metadata: {
      totalNotes: notes.length,
      totalFolders: folders.length,
      exportDate: new Date().toISOString(),
      appVersion: '0.1.0', // Match with package.json
    },
  };

  // Compress the data
  const jsonString = JSON.stringify(data);
  const compressedData = compressData(jsonString);

  // Create binary format
  const encoder = new TextEncoder();
  const signature = encoder.encode(FILE_SIGNATURE);
  const version = new Uint8Array([CURRENT_VERSION]);
  const content = encoder.encode(compressedData);

  // Combine all parts
  const blob = new Blob([signature, version, content], { type: MIME_TYPE });
  return blob;
}

export async function parseMeraNotesFile(file: File): Promise<MeraNotesFile> {
  // Verify file type
  if (!file.name.endsWith('.meranotes')) {
    throw new Error('Invalid file format. Please select a .meranotes file.');
  }

  const arrayBuffer = await file.arrayBuffer();
  const buffer = new Uint8Array(arrayBuffer);

  // Verify signature
  const decoder = new TextDecoder();
  const signature = decoder.decode(buffer.slice(0, FILE_SIGNATURE.length));
  if (signature !== FILE_SIGNATURE) {
    throw new Error('Invalid file signature.');
  }

  // Check version
  const version = buffer[FILE_SIGNATURE.length];
  if (version > CURRENT_VERSION) {
    throw new Error('This file was created with a newer version of MeraNotes.');
  }

  // Extract and decompress content
  const content = decoder.decode(buffer.slice(FILE_SIGNATURE.length + 1));
  const decompressedData = decompressData(content);

  try {
    const data: MeraNotesFile = JSON.parse(decompressedData);
    validateMeraNotesFile(data);
    return data;
  } catch (error) {
    throw new Error('Failed to parse file content.');
  }
}

function validateMeraNotesFile(data: MeraNotesFile): void {
  if (!data || typeof data !== 'object') {
    throw new Error('Invalid file structure.');
  }

  if (!Array.isArray(data.notes) || !Array.isArray(data.folders)) {
    throw new Error('Invalid data format.');
  }

  // Validate each note
  for (const note of data.notes) {
    if (!note.id || typeof note.title !== 'string' || typeof note.content !== 'string') {
      throw new Error('Invalid note data structure.');
    }
  }

  // Validate each folder
  for (const folder of data.folders) {
    if (!folder.id || typeof folder.name !== 'string') {
      throw new Error('Invalid folder data structure.');
    }
  }
}

export function registerFileHandler(): void {
  if ('launchQueue' in window && 'LaunchParams' in window) {
    (window as any).launchQueue.setConsumer(async (launchParams: { files: FileSystemFileHandle[] }) => {
      if (!launchParams.files.length) return;

      try {
        const file = await launchParams.files[0].getFile();
        if (file.name.endsWith('.meranotes')) {
          const data = await parseMeraNotesFile(file);
          // Handle the imported data
          window.dispatchEvent(new CustomEvent('meranotes-import', { detail: data }));
        }
      } catch (error) {
        console.error('Failed to handle file:', error);
      }
    });
  }
}

export function downloadMeraNotesFile(blob: Blob, filename: string): void {
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = filename.endsWith('.meranotes') ? filename : `${filename}.meranotes`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}