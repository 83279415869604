import React from 'react';

export function Logo() {
  return (
    <div className="flex items-center gap-3 p-4 border-b border-gray-200 dark:border-gray-800">
      <img 
        src="/meranotes.png"
        alt="MeraNotes Logo"
        className="w-8 h-8 rounded-lg object-cover"
      />
      <div>
        <h1 className="text-lg font-semibold text-gray-900 dark:text-white">MeraNotes</h1>
        <p className="text-xs text-gray-500 dark:text-gray-400">Secure Note Taking</p>
      </div>
    </div>
  );
}