import React from 'react';
import { Plus } from 'lucide-react';
import { useNotesStore } from '../store/useNotesStore';
import { useFoldersStore } from '../store/useFoldersStore';

export function EmptyState() {
  const { addNote } = useNotesStore();
  const { activeFolderId } = useFoldersStore();

  return (
    <div className="flex-1 flex items-center justify-center bg-white dark:bg-black">
      <div className="text-center p-8 max-w-md">
        <img 
          src="/meranotes.png" 
          alt="MeraNotes Logo" 
          className="w-24 h-24 mx-auto mb-6"
        />
        <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-3">Welcome to MeraNotes</h2>
        <p className="text-gray-600 dark:text-gray-400 mb-8">
          Your secure space for ideas. Create your first note to get started - no signup needed!
        </p>
        <button
          onClick={() => addNote(activeFolderId)}
          className="inline-flex items-center gap-2 bg-black dark:bg-white text-white dark:text-black px-6 py-3 rounded-lg hover:bg-gray-800 dark:hover:bg-gray-200 transition-colors"
        >
          <Plus size={20} />
          Create New Note
        </button>
      </div>
    </div>
  );
}