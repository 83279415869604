import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useNotesStore } from '../../store/useNotesStore';
import { useFoldersStore } from '../../store/useFoldersStore';
import { EmptyState } from '../EmptyState';
import { EditorHeader } from './EditorHeader';
import { EditorContent } from './EditorContent';
import { ImportedNotesBanner } from '../ImportedNotesBanner';
import { Note } from '../../types/note';
import { useStorage } from '../../hooks/useStorage';
import { SaveStatus } from './SaveStatus';
import { useResponsive } from '../../hooks/useResponsive';

export function Editor() {
  const { notes, activeNoteId, updateNote } = useNotesStore();
  const { folders } = useFoldersStore();
  const { isSidebarVisible } = useResponsive();
  const { updateStatus } = useStorage();

  const currentNote = useMemo(() => {
    return notes.find(note => note.id === activeNoteId) || null;
  }, [notes, activeNoteId]);

  const handleUpdateNote = useCallback((id: string, updates: Partial<Note>) => {
    updateNote(id, updates);
  }, [updateNote]);

  const copyToClipboard = async () => {
    if (!currentNote) return;
    try {
      await navigator.clipboard.writeText(currentNote.content);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  if (!currentNote) {
    return <EmptyState />;
  }

  return (
    <div className="flex-1 flex flex-col h-full bg-white dark:bg-black max-w-full overflow-hidden">
      <EditorHeader
        note={currentNote}
        folders={folders}
        isSidebarVisible={isSidebarVisible}
        onUpdateNote={handleUpdateNote}
        onCopy={copyToClipboard}
      />
      {currentNote.importMetadata && (
        <ImportedNotesBanner note={currentNote} />
      )}
      <EditorContent
        note={currentNote}
        onUpdateNote={handleUpdateNote}
      />
      <SaveStatus status={updateStatus} />
    </div>
  );
}