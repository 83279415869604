import React, { useState, useEffect } from 'react';
import { X, Wand2, Settings, AlertCircle, Lightbulb } from 'lucide-react';
import { useNotesStore } from '../store/useNotesStore';
import { useFoldersStore } from '../store/useFoldersStore';
import { verifyOpenAIKey, generateNoteContent, generateSuggestions, AVAILABLE_CONTENT_TYPES, AVAILABLE_TONES } from '../utils/openai';
import { validateAINoteForm } from '../utils/validation/aiNoteValidation';

interface AINoteDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onOpenSettings: () => void;
}

const initialFormState = {
  title: '',
  description: '',
  contentType: '',
  tone: '',
  targetAudience: '',
  keyPoints: [''],
  length: 'medium'
};

export function AINoteDialog({ isOpen, onClose, onOpenSettings }: AINoteDialogProps) {
  const [formData, setFormData] = useState(initialFormState);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuggestingContent, setIsSuggestingContent] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [validation, setValidation] = useState({ success: true, errors: {} });
  const { addNote } = useNotesStore();
  const { activeFolderId } = useFoldersStore();

  useEffect(() => {
    if (!isOpen) {
      setFormData(initialFormState);
      setError(null);
      setValidation({ success: true, errors: {} });
    } else {
      checkApiKey();
    }
  }, [isOpen]);

  const handleClose = () => {
    setFormData(initialFormState);
    setError(null);
    setValidation({ success: true, errors: {} });
    onClose();
  };

  const checkApiKey = async () => {
    const apiKey = localStorage.getItem('openai_api_key');
    if (!apiKey) {
      setError('Please add your OpenAI API key in Settings before using AI features.');
      return false;
    }

    try {
      const isValid = await verifyOpenAIKey(apiKey);
      if (!isValid) {
        setError('Invalid OpenAI API key. Please check your settings.');
        return false;
      }
      setError(null);
      return true;
    } catch (err) {
      setError('Failed to verify API key. Please check your settings.');
      return false;
    }
  };

  const handleSuggestContent = async () => {
    if (!formData.title.trim()) {
      setValidation({
        success: false,
        errors: { title: 'Please enter a title first' }
      });
      return;
    }

    try {
      setIsSuggestingContent(true);
      const apiKey = localStorage.getItem('openai_api_key');
      if (!apiKey) throw new Error('No API key found');

      const suggestions = await generateSuggestions(formData.title, apiKey);
      
      setFormData(prev => ({
        ...prev,
        description: suggestions.description,
        contentType: suggestions.contentType,
        tone: suggestions.tone,
        targetAudience: suggestions.targetAudience,
        keyPoints: suggestions.keyPoints.length > 0 ? suggestions.keyPoints : ['']
      }));
    } catch (error) {
      console.error('Failed to generate suggestions:', error);
      setError('Failed to generate suggestions. Please try again.');
    } finally {
      setIsSuggestingContent(false);
    }
  };

  const handleGenerate = async () => {
    const validationResult = validateAINoteForm(formData);
    if (!validationResult.success) {
      setValidation(validationResult);
      return;
    }

    if (!await checkApiKey()) return;

    try {
      setIsLoading(true);
      const apiKey = localStorage.getItem('openai_api_key');
      if (!apiKey) throw new Error('No API key found');

      const content = await generateNoteContent(formData, apiKey);
      await addNote(activeFolderId, {
        title: formData.title,
        content
      });
      
      handleClose();
    } catch (error) {
      console.error('Failed to generate note:', error);
      setError('Failed to generate note. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const addKeyPoint = () => {
    setFormData(prev => ({
      ...prev,
      keyPoints: [...prev.keyPoints, '']
    }));
  };

  const updateKeyPoint = (index: number, value: string) => {
    setFormData(prev => ({
      ...prev,
      keyPoints: prev.keyPoints.map((point, i) => i === index ? value : point)
    }));
  };

  const removeKeyPoint = (index: number) => {
    setFormData(prev => ({
      ...prev,
      keyPoints: prev.keyPoints.filter((_, i) => i !== index)
    }));
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-900 rounded-lg shadow-lg max-w-2xl w-full mx-4 animate-scale-in">
        <div className="flex items-center justify-between p-6 border-b border-gray-200 dark:border-gray-800">
          <div className="flex items-center gap-2">
            <Wand2 className="w-5 h-5 text-indigo-500" />
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white">Create AI Note</h2>
          </div>
          <button
            onClick={handleClose}
            className="p-2 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-full transition-colors"
          >
            <X size={20} className="text-gray-500 dark:text-gray-400" />
          </button>
        </div>

        {error && (
          <div className="p-4 bg-red-50 dark:bg-red-900/20 border-b border-red-100 dark:border-red-900/50">
            <div className="flex items-center gap-2">
              <AlertCircle className="w-5 h-5 text-red-500" />
              <p className="text-red-600 dark:text-red-400 text-sm">{error}</p>
            </div>
            <button
              onClick={() => {
                onOpenSettings();
                onClose();
              }}
              className="flex items-center gap-2 text-red-600 dark:text-red-400 text-sm mt-2 hover:underline"
            >
              <Settings size={16} />
              Open Settings
            </button>
          </div>
        )}

        <div className="p-6 max-h-[calc(100vh-200px)] overflow-y-auto">
          <div className="space-y-6">
            <div>
              <div className="flex items-center justify-between mb-2">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Title
                </label>
                <button
                  onClick={handleSuggestContent}
                  disabled={isSuggestingContent || !formData.title.trim()}
                  className="flex items-center gap-1 text-sm text-indigo-600 dark:text-indigo-400 hover:text-indigo-700 dark:hover:text-indigo-300 disabled:opacity-50"
                >
                  <Lightbulb size={16} />
                  <span>{isSuggestingContent ? 'Suggesting...' : 'Suggest Content'}</span>
                </button>
              </div>
              <input
                type="text"
                value={formData.title}
                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:bg-gray-800 dark:text-white ${
                  validation.errors.title ? 'border-red-500' : 'border-gray-300 dark:border-gray-700'
                }`}
                placeholder="Enter a title for your note"
              />
              {validation.errors.title && (
                <p className="mt-1 text-sm text-red-500">{validation.errors.title}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Brief Description (AI Prompt)
              </label>
              <textarea
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:bg-gray-800 dark:text-white ${
                  validation.errors.description ? 'border-red-500' : 'border-gray-300 dark:border-gray-700'
                }`}
                rows={3}
                placeholder="Describe what you want the AI to write about..."
              />
              {validation.errors.description && (
                <p className="mt-1 text-sm text-red-500">{validation.errors.description}</p>
              )}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Content Type
                </label>
                <select
                  value={formData.contentType}
                  onChange={(e) => setFormData({ ...formData, contentType: e.target.value })}
                  className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:bg-gray-800 dark:text-white ${
                    validation.errors.contentType ? 'border-red-500' : 'border-gray-300 dark:border-gray-700'
                  }`}
                >
                  <option value="">Select type...</option>
                  {AVAILABLE_CONTENT_TYPES.map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
                {validation.errors.contentType && (
                  <p className="mt-1 text-sm text-red-500">{validation.errors.contentType}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Tone
                </label>
                <select
                  value={formData.tone}
                  onChange={(e) => setFormData({ ...formData, tone: e.target.value })}
                  className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:bg-gray-800 dark:text-white ${
                    validation.errors.tone ? 'border-red-500' : 'border-gray-300 dark:border-gray-700'
                  }`}
                >
                  <option value="">Select tone...</option>
                  {AVAILABLE_TONES.map((tone) => (
                    <option key={tone} value={tone}>
                      {tone.charAt(0).toUpperCase() + tone.slice(1)}
                    </option>
                  ))}
                </select>
                {validation.errors.tone && (
                  <p className="mt-1 text-sm text-red-500">{validation.errors.tone}</p>
                )}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Target Audience
              </label>
              <input
                type="text"
                value={formData.targetAudience}
                onChange={(e) => setFormData({ ...formData, targetAudience: e.target.value })}
                className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:bg-gray-800 dark:text-white ${
                  validation.errors.targetAudience ? 'border-red-500' : 'border-gray-300 dark:border-gray-700'
                }`}
                placeholder="Who is this content for?"
              />
              {validation.errors.targetAudience && (
                <p className="mt-1 text-sm text-red-500">{validation.errors.targetAudience}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Key Points
              </label>
              <div className="space-y-2">
                {formData.keyPoints.map((point, index) => (
                  <div key={index} className="flex gap-2">
                    <input
                      type="text"
                      value={point}
                      onChange={(e) => updateKeyPoint(index, e.target.value)}
                      className={`flex-1 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:bg-gray-800 dark:text-white ${
                        validation.errors[`keyPoints.${index}`] ? 'border-red-500' : 'border-gray-300 dark:border-gray-700'
                      }`}
                      placeholder={`Key point ${index + 1}`}
                    />
                    {formData.keyPoints.length > 1 && (
                      <button
                        onClick={() => removeKeyPoint(index)}
                        className="px-3 py-2 text-red-500 hover:bg-red-50 dark:hover:bg-red-900/20 rounded-lg transition-colors"
                      >
                        Remove
                      </button>
                    )}
                  </div>
                ))}
                <button
                  onClick={addKeyPoint}
                  className="text-sm text-indigo-600 dark:text-indigo-400 hover:text-indigo-700 dark:hover:text-indigo-300"
                >
                  + Add another key point
                </button>
                {validation.errors.keyPoints && (
                  <p className="mt-1 text-sm text-red-500">{validation.errors.keyPoints}</p>
                )}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Length
              </label>
              <div className="flex gap-4">
                {['brief', 'medium', 'long', 'comprehensive'].map((length) => (
                  <label key={length} className="flex items-center">
                    <input
                      type="radio"
                      name="length"
                      value={length}
                      checked={formData.length === length}
                      onChange={(e) => setFormData({ ...formData, length: e.target.value })}
                      className="mr-2"
                    />
                    <span className="text-sm text-gray-700 dark:text-gray-300 capitalize">
                      {length}
                    </span>
                  </label>
                ))}
              </div>
              {validation.errors.length && (
                <p className="mt-1 text-sm text-red-500">{validation.errors.length}</p>
              )}
            </div>
          </div>
        </div>

        <div className="px-6 py-4 bg-gray-50 dark:bg-gray-800 rounded-b-lg flex justify-end gap-3">
          <button
            onClick={handleClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={handleGenerate}
            disabled={isLoading || !!error}
            className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
          >
            {isLoading ? (
              <>
                <span className="animate-spin">⌛</span>
                Generating...
              </>
            ) : (
              <>
                <Wand2 size={16} />
                Generate
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}