import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useEditor, EditorContent as TiptapContent } from '@tiptap/react';
import { editorExtensions } from './config/extensions';
import { Note } from '../../types/note';
import { EditorToolbar } from './EditorToolbar';
import { useStorage } from '../../hooks/useStorage';
import { createEditorProps } from './config/props';

interface EditorContentProps {
  note: Note;
  onUpdateNote: (id: string, updates: Partial<Note>) => void;
}

export function EditorContent({ note, onUpdateNote }: EditorContentProps) {
  const { updateContent } = useStorage();
  const [isEmpty, setIsEmpty] = useState(true);
  const [isFocused, setIsFocused] = useState(false);

  const handleUpdate = useCallback(async (content: string) => {
    if (content !== note.content) {
      await updateContent(note.id, content);
      onUpdateNote(note.id, { content, lastModified: Date.now() });
    }
    setIsEmpty(content === '<p></p>' || content === '' || content === '<p></p><p></p>');
  }, [note.id, note.content, updateContent, onUpdateNote]);

  const editorProps = useMemo(() => createEditorProps({
    isEmpty,
    isFocused,
    placeholder: 'Start writing your note here...'
  }), [isEmpty, isFocused]);

  const editor = useEditor({
    extensions: editorExtensions,
    content: note.content || '<p></p>',
    enableInputRules: true,
    enablePasteRules: true,
    editorProps,
    onCreate: ({ editor }) => {
      setIsEmpty(editor.isEmpty);
    },
    onUpdate: ({ editor, transaction }) => {
      const html = editor.getHTML();
      setIsEmpty(editor.isEmpty);
      handleUpdate(html);
    },
    onFocus: () => {
      setIsFocused(true);
    },
    onBlur: () => {
      setIsFocused(false);
    }
  });

  useEffect(() => {
    if (editor && note.content !== editor.getHTML()) {
      editor.commands.setContent(note.content || '<p></p>');
    }
  }, [editor, note.id, note.content]);

  if (!editor) {
    return null;
  }

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <EditorToolbar editor={editor} />
      <div className={`flex-1 overflow-y-auto px-2 sm:px-4 ${isEmpty && !isFocused ? 'editor-empty' : ''}`}>
        <div className="max-w-full overflow-x-hidden">
          <TiptapContent editor={editor} className="max-w-full overflow-x-hidden break-words" />
        </div>
      </div>
    </div>
  );
}