import React, { useEffect, useState } from 'react';
import { getSharedNote, checkFileExists } from '../utils/supabase/storage';
import { Note } from '../types/note';
import { useNotesStore } from '../store/useNotesStore';
import { useFoldersStore } from '../store/useFoldersStore';
import { AlertTriangle, Download, FileText } from 'lucide-react';
import { ERRORS } from '../utils/supabase/config';

interface SharedNotePreviewProps {
  shareId: string;
}

export function SharedNotePreview({ shareId }: SharedNotePreviewProps) {
  const [note, setNote] = useState<Note | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isImporting, setIsImporting] = useState(false);
  const { addNote } = useNotesStore();
  const { ensureImportedFolder } = useFoldersStore();

  useEffect(() => {
    async function loadSharedNote() {
      try {
        setIsLoading(true);
        setError(null);

        // First check if file exists
        const exists = await checkFileExists(shareId);
        if (!exists) {
          throw new Error(ERRORS.NOT_FOUND);
        }

        const sharedNote = await getSharedNote(shareId);
        if (!sharedNote) {
          throw new Error(ERRORS.NOT_FOUND);
        }

        setNote(sharedNote);
      } catch (error) {
        console.error('Failed to load shared note:', error);
        setError(error instanceof Error ? error.message : ERRORS.NOT_FOUND);
      } finally {
        setIsLoading(false);
      }
    }

    if (shareId) {
      loadSharedNote();
    }
  }, [shareId]);

  const handleImport = async () => {
    if (!note) return;

    try {
      setIsImporting(true);
      const importedFolderId = ensureImportedFolder();
      
      await addNote(importedFolderId, {
        ...note,
        importMetadata: {
          source: 'shared',
          originalId: note.id,
          importedAt: Date.now()
        }
      });

      window.location.href = '/';
    } catch (error) {
      console.error('Failed to import note:', error);
      setError(error instanceof Error ? error.message : 'Failed to import note');
    } finally {
      setIsImporting(false);
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-900">
        <div className="text-center">
          <div className="w-16 h-16 border-4 border-indigo-600 border-t-transparent rounded-full animate-spin mx-auto mb-4"></div>
          <p className="text-gray-600 dark:text-gray-300">Loading shared note...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-900">
        <div className="max-w-md w-full mx-4 p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg text-center">
          <AlertTriangle className="w-16 h-16 text-red-500 mx-auto mb-4" />
          <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
            {error === ERRORS.NOT_FOUND ? 'Note Not Found' : 'Error Loading Note'}
          </h2>
          <p className="text-gray-600 dark:text-gray-300 mb-6">{error}</p>
          <a
            href="/"
            className="inline-flex items-center gap-2 bg-indigo-600 text-white px-4 py-2 rounded-lg hover:bg-indigo-700 transition-colors"
          >
            Go to Home
          </a>
        </div>
      </div>
    );
  }

  if (!note) return null;

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden">
          <div className="p-6 border-b border-gray-200 dark:border-gray-700">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <FileText className="w-8 h-8 text-indigo-500" />
                <div>
                  <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
                    {note.title}
                  </h1>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    Shared note • Last modified {new Date(note.lastModified).toLocaleDateString()}
                  </p>
                </div>
              </div>
              <button
                onClick={handleImport}
                disabled={isImporting}
                className="flex items-center gap-2 bg-indigo-600 text-white px-4 py-2 rounded-lg hover:bg-indigo-700 transition-colors disabled:opacity-50"
              >
                <Download size={18} />
                {isImporting ? 'Importing...' : 'Import Note'}
              </button>
            </div>
          </div>
          <div className="p-6">
            <div 
              className="prose prose-sm sm:prose lg:prose-lg xl:prose-xl dark:prose-invert max-w-none"
              dangerouslySetInnerHTML={{ __html: note.content }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}