import { debounce } from 'lodash';
import { StorageOptions, StorageUpdate } from './types';
import { UpdateQueue } from './queue';
import { validateContent, sanitizeContent } from './validation';
import { storage } from '../database';

class StorageManager {
  private queue: UpdateQueue;
  private options: StorageOptions;
  private saveDebounced: (update: StorageUpdate) => void;

  constructor(options: StorageOptions = {}) {
    this.options = {
      debounceMs: 2000, // Increased to 2 seconds per requirement
      maxRetries: 3,
      retryDelayMs: 1000,
      ...options
    };

    this.queue = new UpdateQueue(this.options);
    this.saveDebounced = debounce(this.processUpdate.bind(this), this.options.debounceMs);
  }

  public async updateContent(id: string, content: string): Promise<void> {
    try {
      if (!validateContent(content, this.options)) {
        throw new Error('Invalid content');
      }
      const sanitizedContent = sanitizeContent(content);

      const update: StorageUpdate = {
        id,
        content: sanitizedContent,
        timestamp: Date.now(),
        status: 'pending'
      };

      this.queue.add(update);
      this.saveDebounced(update);

    } catch (error) {
      console.error('Failed to queue update:', error);
      throw error;
    }
  }

  private async processUpdate(update: StorageUpdate): Promise<void> {
    if (this.queue.isProcessing()) {
      return;
    }

    try {
      this.queue.markAsProcessing(update.id);

      const notes = await storage.getAllNotes();
      const note = notes.find(n => n.id === update.id);
      
      if (!note) {
        throw new Error('Note not found');
      }

      await storage.saveNote({
        ...note,
        content: update.content,
        lastModified: Date.now()
      });

      this.queue.markAsComplete(update.id);

    } catch (error) {
      console.error('Failed to process update:', error);
      this.queue.markAsError(update.id);

      const failedUpdate = this.queue.get(update.id);
      if (failedUpdate?.status === 'pending') {
        setTimeout(() => {
          this.processUpdate(failedUpdate);
        }, this.options.retryDelayMs);
      }
    }
  }

  public getUpdateStatus(id: string): string {
    const update = this.queue.get(id);
    return update?.status || 'unknown';
  }

  public clearQueue(): void {
    this.queue.clear();
  }
}

export const storageManager = new StorageManager({
  debounceMs: 2000,
  maxRetries: 3,
  retryDelayMs: 1000,
  validationRules: {
    maxSize: 10 * 1024 * 1024,
    allowedTypes: ['text/html', 'text/plain']
  }
});