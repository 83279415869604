import React from 'react';
import { useNavigate } from '../../hooks/useNavigate';

export function KnowMore() {
  const { navigateToFolder } = useNavigate();

  const handleClick = () => {
    // Store current state before navigation
    localStorage.setItem('returnToFolder', window.location.pathname);
    window.location.href = '/about';
  };

  return (
    <div className="p-3">
      <button
        onClick={handleClick}
        className="relative group overflow-hidden w-full rounded-lg shadow hover:shadow-md transition-all duration-300"
      >
        <div className="px-4 py-2 bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-600 animate-gradient bg-[length:200%_200%]">
          <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-10 dark:bg-white dark:group-hover:opacity-5 transition-opacity duration-200" />
          <p className="text-white text-center font-medium text-sm">
            Know More
          </p>
        </div>
      </button>
    </div>
  );
}