import { openDB, IDBPDatabase } from 'idb';
import { compressData, decompressData } from './compression';
import { Note } from '../../types/note';
import { 
  saveNoteTransaction, 
  deleteNoteTransaction, 
  getAllNotesTransaction,
  executeTransaction 
} from './transactions';

const DB_NAME = 'meranotes-db';
const DB_VERSION = 1;
const NOTES_STORE = 'notes';
const META_STORE = 'meta';
const MAX_NOTE_SIZE = 10 * 1024 * 1024; // 10MB

interface StorageMeta {
  version: number;
  totalSize: number;
  lastBackup?: number;
}

class StorageManager {
  private db: IDBPDatabase | null = null;
  private connecting: Promise<IDBPDatabase> | null = null;
  private initialized = false;

  private async getDB(): Promise<IDBPDatabase> {
    if (this.db) return this.db;
    if (this.connecting) return this.connecting;

    this.connecting = openDB(DB_NAME, DB_VERSION, {
      upgrade(db) {
        if (!db.objectStoreNames.contains(NOTES_STORE)) {
          const notesStore = db.createObjectStore(NOTES_STORE, { keyPath: 'id' });
          notesStore.createIndex('folderId', 'folderId');
          notesStore.createIndex('lastModified', 'lastModified');
          notesStore.createIndex('deleted', 'deleted');
        }

        if (!db.objectStoreNames.contains(META_STORE)) {
          db.createObjectStore(META_STORE);
        }
      },
      blocked() {
        console.warn('Database upgrade blocked. Please close other tabs.');
      },
      blocking() {
        this.db?.close();
        this.db = null;
      },
      terminated() {
        this.db = null;
      },
    });

    try {
      this.db = await this.connecting;
      return this.db;
    } finally {
      this.connecting = null;
    }
  }

  async init(): Promise<void> {
    if (this.initialized) return;
    
    try {
      const db = await this.getDB();
      if (!db) throw new Error('Failed to initialize database');
      
      await this.migrateFromLocalStorage();
      this.initialized = true;
    } catch (error) {
      console.error('Failed to initialize storage:', error);
      throw error;
    }
  }

  async saveNote(note: Note): Promise<void> {
    if (!this.initialized) await this.init();

    const noteSize = new Blob([JSON.stringify(note)]).size;
    if (noteSize > MAX_NOTE_SIZE) {
      throw new Error('Note exceeds maximum size limit');
    }

    let noteToSave = { ...note };
    if (noteSize > 100 * 1024) {
      noteToSave = {
        ...noteToSave,
        content: compressData(noteToSave.content),
        compressed: true,
      };
    }

    const db = await this.getDB();
    await saveNoteTransaction(db, noteToSave);
  }

  async getAllNotes(): Promise<Note[]> {
    if (!this.initialized) await this.init();

    const db = await this.getDB();
    const notes = await getAllNotesTransaction(db);
    
    return notes
      .filter(note => !note.deleted)
      .map(note => {
        if (note.compressed) {
          return {
            ...note,
            content: decompressData(note.content),
            compressed: false,
          };
        }
        return note;
      });
  }

  async deleteNote(id: string): Promise<void> {
    if (!this.initialized) await this.init();

    const db = await this.getDB();
    await deleteNoteTransaction(db, id);
  }

  async migrateFromLocalStorage(): Promise<void> {
    const storedNotes = localStorage.getItem('notes');
    if (!storedNotes) return;

    try {
      const notes = JSON.parse(storedNotes) as Note[];
      for (const note of notes) {
        if (!note.deleted) {
          await this.saveNote({ ...note, deleted: false });
        }
      }
      localStorage.removeItem('notes');
    } catch (error) {
      console.error('Migration failed:', error);
      throw error;
    }
  }

  async checkStorageAvailability(): Promise<boolean> {
    try {
      const db = await this.getDB();
      const testNote: Note = {
        id: 'test',
        title: 'Test Note',
        content: 'Test content',
        lastModified: Date.now(),
        folderId: null,
        deleted: false,
      };
      
      await saveNoteTransaction(db, testNote);
      await deleteNoteTransaction(db, 'test');
      return true;
    } catch (error) {
      console.error('Storage not available:', error);
      return false;
    }
  }
}

export const storage = new StorageManager();