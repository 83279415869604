import { z } from 'zod';
import { AVAILABLE_CONTENT_TYPES, AVAILABLE_TONES } from '../openai';

export const aiNoteSchema = z.object({
  title: z.string()
    .min(1, 'Title is required')
    .max(100, 'Title must be less than 100 characters'),
  description: z.string()
    .min(10, 'Description must be at least 10 characters')
    .max(500, 'Description must be less than 500 characters'),
  contentType: z.enum(AVAILABLE_CONTENT_TYPES as [string, ...string[]], {
    errorMap: () => ({ message: 'Please select a valid content type' })
  }),
  tone: z.enum(AVAILABLE_TONES as [string, ...string[]], {
    errorMap: () => ({ message: 'Please select a valid tone' })
  }),
  targetAudience: z.string()
    .min(3, 'Target audience must be at least 3 characters')
    .max(100, 'Target audience must be less than 100 characters'),
  keyPoints: z.array(z.string())
    .min(1, 'At least one key point is required')
    .max(5, 'Maximum 5 key points allowed')
    .refine(points => points.some(point => point.trim().length > 0), {
      message: 'At least one non-empty key point is required'
    })
});

export type AINoteFormData = z.infer<typeof aiNoteSchema>;

export interface ValidationResult {
  success: boolean;
  errors: { [key: string]: string };
}

export function validateAINoteForm(data: any): ValidationResult {
  try {
    aiNoteSchema.parse(data);
    return { success: true, errors: {} };
  } catch (error) {
    if (error instanceof z.ZodError) {
      const errors = error.errors.reduce((acc, err) => {
        const path = err.path.join('.');
        acc[path] = err.message;
        return acc;
      }, {} as { [key: string]: string });
      return { success: false, errors };
    }
    return { 
      success: false, 
      errors: { form: 'Invalid form data' }
    };
  }
}