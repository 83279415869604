import React, { useEffect, useState } from 'react';
import { useNavigate } from '../../hooks/useNavigate';
import { initializeEnvironment } from '../../utils/share/initialization';
import { validateShareLink } from '../../utils/share/validation';
import { SharedNotePreview } from '../SharedNotePreview';
import { StorageError } from '../StorageError';

interface ShareLinkHandlerProps {
  shareId: string;
}

export function ShareLinkHandler({ shareId }: ShareLinkHandlerProps) {
  const [isInitializing, setIsInitializing] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { navigateToFolder } = useNavigate();

  useEffect(() => {
    async function handleShareLink() {
      try {
        setIsInitializing(true);
        setError(null);

        // Validate share link
        const validation = await validateShareLink(window.location.href);
        if (!validation.isValid) {
          throw new Error(validation.error);
        }

        // Initialize environment if needed
        const initialized = await initializeEnvironment();
        if (!initialized) {
          throw new Error('Failed to initialize application');
        }
      } catch (error) {
        console.error('Share link handling failed:', error);
        setError(error instanceof Error ? error.message : 'Failed to process share link');
      } finally {
        setIsInitializing(false);
      }
    }

    handleShareLink();
  }, [shareId]);

  if (isInitializing) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-900">
        <div className="text-center">
          <div className="w-16 h-16 border-4 border-indigo-600 border-t-transparent rounded-full animate-spin mx-auto mb-4"></div>
          <p className="text-gray-600 dark:text-gray-300">Preparing to load shared note...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <StorageError 
        message={error}
        onRetry={() => window.location.reload()}
      />
    );
  }

  return <SharedNotePreview shareId={shareId} />;
}