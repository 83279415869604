import React, { useEffect, useState } from 'react';
import { Sidebar } from './components/Sidebar';
import { Editor } from './components/Editor';
import { FolderPage } from './components/FolderPage';
import { ShareLinkHandler } from './components/share/ShareLinkHandler';
import { MobileNav } from './components/MobileNav';
import { InstallPWA } from './components/InstallPWA';
import { StorageError } from './components/StorageError';
import { AboutPage } from './components/san-juniper/AboutPage';
import { useNotesStore } from './store/useNotesStore';
import { useBackButton } from './hooks/useBackButton';

function App() {
  const { initializeNotes, error, isLoading, activeNoteId } = useNotesStore();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useBackButton();

  useEffect(() => {
    initializeNotes();
  }, [initializeNotes]);

  // Check if we're on the about page
  if (window.location.pathname === '/about') {
    return <AboutPage />;
  }

  // Check if we're on a share page
  const shareMatch = window.location.pathname.match(/^\/share\/([^/]+)/);
  if (shareMatch) {
    return <ShareLinkHandler shareId={shareMatch[1]} />;
  }

  if (error) {
    return <StorageError message={error} onRetry={initializeNotes} />;
  }

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-900">
        <div className="text-center">
          <div className="w-16 h-16 border-4 border-indigo-600 border-t-transparent rounded-full animate-spin mx-auto mb-4"></div>
          <p className="text-gray-600 dark:text-gray-300">Loading your notes...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-screen bg-white dark:bg-black relative">
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-30 md:hidden"
          onClick={() => setIsSidebarOpen(false)}
          aria-hidden="true"
        />
      )}
      <Sidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} />
      <div className="flex-1 flex flex-col">
        {activeNoteId ? <Editor /> : <FolderPage />}
      </div>
      <MobileNav onOpenSidebar={() => setIsSidebarOpen(true)} />
      <InstallPWA />
    </div>
  );
}

export default App;