import { useCallback } from 'react';
import { useNotesStore } from '../store/useNotesStore';
import { useFoldersStore } from '../store/useFoldersStore';

export function useNavigate() {
  const { setActiveNote } = useNotesStore();
  const { setActiveFolderId } = useFoldersStore();

  const navigateToFolder = useCallback((folderId: string | null) => {
    setActiveNote(null);
    setActiveFolderId(folderId);
  }, [setActiveNote, setActiveFolderId]);

  return { navigateToFolder };
}